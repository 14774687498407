<template>
  <v-card min-height="185px" flat>
    <v-list>
      <div v-for="(item, i) in utilityCompaniesItemsList.types" :key="i">
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="utilityCompanyIconByType[item].color">
              {{ utilityCompanyIconByType[item].icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content class="ml-n4">
            <v-list-item-title>
              <span class="primary--text font-weight-bold">
                {{ item }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="i !== utilityCompaniesItemsList.types.length - 1" />
      </div>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'UtilityCompaniesItemsList',

  props: {
    utilityCompaniesItemsList: {
      type: Object,
      required: true,
    },
  },

  computed: {
    utilityCompanyIconByType() {
      return {
        Water: {
          icon: 'mdi-water',
          color: 'intechBlue',
        },
        Gas: {
          icon: 'mdi-gas-burner',
          color: 'heatColor',
        },
        Electricity: {
          icon: 'mdi-flash',
          color: 'warning',
        },
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.v-expansion-panel:first-child
    border-top: none
</style>
