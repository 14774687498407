import API from '@/Core.Service.Domain/ViewSupport/Reports/API';

const setScheduledReportsList = async ({ commit }) => {
  commit('SET_SCHEDULED_REPORTS_LIST_LOADING', true);
  try {
    const scheduledReportsList = await API.getScheduledReportsList();
    commit('SET_SCHEDULED_REPORTS_LIST', scheduledReportsList);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    commit('SET_SCHEDULED_REPORTS_LIST_LOADING', false);
  }
};

export default {
  setScheduledReportsList,
};
