<template>
  <v-card>
    <v-row>
      <v-col cols="3">
        <v-tabs>
          <v-tab @click="selectedTab = 'unread'">
            <v-badge
              v-if="messagesCount > 0"
              :content="messagesCount"
              :value="messagesCount"
              overlap
              right
              color="accentOrange"
              offset-x="0"
              offset-y="0"
            >
              Unread
            </v-badge>
            <span v-else> Unread </span>
          </v-tab>
          <v-tab @click="selectedTab = 'read'"> Read </v-tab>
          <v-tab-item>
            <v-list>
              <v-list-item
                v-for="(item, i) in getUnreadMessagesList"
                :key="i"
                @click="selectedMessage = item"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="primary--text text-subtitle-2">
                      {{ item.messageCenterMessageLabel }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>
          <v-tab-item>
            <v-list>
              <v-list-item
                v-for="(item, i) in getReadMessagesList"
                :key="i"
                @click="selectedMessage = item"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="primary--text text-subtitle-2">
                      {{ item.messageCenterMessageLabel }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>
        </v-tabs>
      </v-col>
      <v-col cols="9">
        <v-alert
          icon="mdi-email-open-outline"
          prominent
          text
          type="info"
          color="primary"
          class="ma-3"
          v-if="
            isReadMessagesListEmptyAndTabIsRead ||
            isUnreadMessagesListEmptyAndTabIsUnread
          "
        >
          {{
            `You have no ${
              isUnreadMessagesListEmptyAndTabIsUnread ? 'Unread' : 'Read'
            } messages.`
          }}
        </v-alert>
        <MessageCenterMessagePanel
          v-else
          @toggle="toggleMessage"
          :selectedMessage="selectedMessage"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import MessageCenterMessagePanel from '@/Core.Service.Domain/MessageCenter/Users/Components/MessageCenterMessagePanel.vue';

import API from '@/Core.Service.Domain/MessageCenter/Users/API';

export default {
  name: 'MessageCenterMessagesList',

  components: {
    MessageCenterMessagePanel,
  },

  computed: {
    ...mapGetters({
      messagesCount: 'MessageCenterUsersStore/getUnreadMessagesCount',
      messagesByUserList: 'MessageCenterUsersStore/getMessagesByUser',
    }),

    getReadMessagesList() {
      return this.messagesByUserList.filter((item) => item.isRead);
    },

    getUnreadMessagesList() {
      return this.messagesByUserList.filter((item) => !item.isRead);
    },

    isReadMessagesListEmptyAndTabIsRead() {
      return (
        this.getReadMessagesList.length === 0 && this.selectedTab === 'read'
      );
    },

    isUnreadMessagesListEmptyAndTabIsUnread() {
      return (
        this.getUnreadMessagesList.length === 0 && this.selectedTab === 'unread'
      );
    },
  },

  watch: {
    $route(to) {
      if (to.name === 'MessageCenterMyMessages') {
        this.handleLoadMessages();
      }
    },
  },

  async mounted() {
    await this.handleLoadMessages();
    if (this.isUnreadMessagesListEmptyAndTabIsUnread) {
      this.selectedTab = 'read';
      this.selectedMessage = this.getReadMessagesList[0];
    } else {
      this.selectedMessage = this.getUnreadMessagesList[0];
    }
  },

  data() {
    return {
      search: '',
      selectedMessage: {},
      itemIsLoading: null,
      selectedTab: 'unread',
    };
  },

  methods: {
    ...mapActions({
      setUnreadMessagesCount: 'MessageCenterUsersStore/setUnreadMessagesCount',
      getMessagesByUser: 'MessageCenterUsersStore/setMessagesByUser',
    }),

    async handleLoadMessages() {
      try {
        await this.getMessagesByUser();
      } catch (error) {
        console.error(error);
      }
    },

    async toggleMessageRead(message) {
      const messageId = message.userMessageCenterMessageId;
      try {
        if (message.isRead) {
          await API.markMessageAsUnread(messageId);
        } else {
          await API.markMessageAsRead(messageId);
        }
        message.isRead = !message.isRead;
        await this.handleLoadMessages();
        await this.setUnreadMessagesCount();
      } catch (error) {
        console.error(error);
      }
    },

    async toggleMessage() {
      await this.handleLoadMessages();
      this.setUnreadMessagesCount();
    },
  },
};
</script>
<style lang="sass" scoped>
.v-tab
    text-transform: none !important
.v-badge::v-deep
  .v-badge__badge
    width: auto
    letter-spacing: -1px
    padding: 2px 5px
    padding-left: 4px
</style>
