<template>
  <v-container fluid class="mt-n2">
    <v-data-table
      :headers="headers"
      :items="pieraMonitorsList"
      :items-per-page="10"
      :search="searchTerm"
      :loading="!pieraMonitorsList.length"
      :header-props="headerProps"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <!--Headers slot-->
      <template v-slot:headers="props">
        <tr>
          <th
            v-for="(header, i) in props.headers"
            :key="i"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Friendly Name slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.friendlyName }}
            </span>
          </td>
          <!--Device Id slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.deviceId }}
            </span>
          </td>
          <!--Device Name slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.deviceName }}
            </span>
          </td>
          <!--Site Location slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.siteLocation.siteLocationName }}
            </span>
          </td>
          <!--Actions buttons slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :outlined="true"
              :disabled="!userCanUpdate || !userCanDelete"
              :item="item"
              class="ml-n2"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--Confirm Dialog-->
    <ConfirmDialogDeletePieraMonitor
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :PieraMonitorInfo="PieraMonitorInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-container>
</template>

<script>
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import ConfirmDialogDeletePieraMonitor from '@/Core.Service.Domain/Monitors/Piera/Components/ConfirmDialogDeletePieraMonitor';

export default {
  name: 'PieraMonitorsList',

  components: {
    ActionButtonsVue,
    ConfirmDialogDeletePieraMonitor,
  },

  computed: {
    ...mapGetters({
      PieraMonitorsList:
        'MonitorsStore/PieraMonitorsStore/selectedSiteWithPieraMonitors',
      selectedPieraMonitorIsLoading:
        'MonitorsStore/PieraMonitorsStore/selectedPieraMonitorIsLoading',
      searchTerm: 'MonitorsStore/PieraMonitorsStore/getPieraMonitorsSearchTerm',
    }),

    pieraMonitorsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems)
        : sortAlphabetically(this.PieraMonitorsList);
    },

    userCanDelete() {
      return this.$can('delete', 'meters');
    },
    userCanUpdate() {
      return this.$can('update', 'meters');
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['deviceName', 'friendlyName'],
        this.PieraMonitorsList
      );
    },
  },

  data(instance) {
    return {
      filteredItems: [],
      showConfirmDialog: false,
      PieraMonitorInfo: {},
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          text: 'Friendly Name',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'friendlyName',
          sortable: true,
        },
        {
          text: 'Device Id',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'deviceId',
          sortable: true,
        },
        {
          text: 'Device Name',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'deviceName',
          sortable: true,
        },
        {
          text: 'Site Location',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'siteLocation.siteLocationName',
          sortable: true,
        },
        {
          text: 'Actions',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'actions',
          sortable: false,
        },
      ],
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },
      actionButtons: [
        {
          icon: 'pencil',
          toolTip: `Edit ${instance.$route.meta.toolTip}`,
          loading: () => instance.selectedPieraMonitorIsLoading,
          action: (item) => {
            if (!this.userCanUpdate || !this.userCanDelete) return;
            instance.$router.push({
              name: 'EditPieraMonitor',
              params: {
                id: item.deviceId,
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: `Delete ${instance.$route.meta.toolTip}`,
          action: (item) => {
            if (!instance.userCanUpdate || !instance.userCanDelete) return;
            instance.PieraMonitorInfo = item;
            instance.showConfirmDialog = true;
          },
        },
      ],
    };
  },

  methods: {
    dateTimeToUSFormatFactory,
  },
};
</script>
