<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="1709.09129,-20.36521,243.22223,305.33333"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="isActive ? '0.3' : '0.2'"
      :fill="color"
      fill-rule="nonzero"
      :stroke="color"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
      pointer-events="all"
    >
      <path
        d="M1709.59129,284.46812v-215.44444h7.77778v-24.55556h-5.55556v-31h5.55556v-22.22222h44.44444v-11.11111h137.66667v11.11111h45.55556v17.66667h5.66667v32.33333h-5.66667v25.44444h6.77778v53.44444h-20v14.44444h-37.88889v-14.44444h-21.11111v-29h-86.66667v193.33333z"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'bADrawing',
  props: {
    color: {
      type: String,
      default: '#2E3C54',
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleMouseEnter(event) {
      if (this.isActive) {
        this.$emit('mouseenter', event);
      }
    },
    handleMouseMove(event) {
      if (this.isActive) {
        this.$emit('mousemove', event);
      }
    },
    handleMouseLeave(event) {
      if (this.isActive) {
        this.$emit('mouseleave', event);
      }
    },
  },
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
