// These routes are old fashioned and will be replaced by the new route system.
// They should be nested hence a router-view in the parent component.

const PermissionsIndexRoute = {
  path: '/administration/permissions',
  name: 'PermissionIndex',
  component: () => import('@/Core.Service.Domain/Permissions/Views/Index'),
  meta: { permission: 'users:read' },
};

const AvailableEnergy360PermissionsRoute = {
  path: '/administration/permissions/energy360core',
  name: 'Permissions',
  component: () =>
    import('@/Core.Service.Domain/Permissions/Views/Index.vue'),
  meta: { permission: 'users:create' },
};

export default [
  PermissionsIndexRoute,
  AvailableEnergy360PermissionsRoute
];
