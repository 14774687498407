import ROIPredictionsStore from '@/Core.Service.Domain/Sites/ROIPredictions/Store';

import UtilityCompaniesStore from '@/Core.Service.Domain/Sites/UtilityCompanies/Store';

export default {
  namespaced: true,
  modules: {
    ROIPredictionsStore,
    UtilityCompaniesStore,
  },
};
