<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="analytics-bar d-flex flex-row"
    class="analytics-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <!-- Add Button -->
      <v-btn
        v-if="userCanUpdate || userCanCreate || userCanDelete"
        class="rounded-pill mr-3"
        color="primary"
        depressed
        large
        @click="handleAddAnalyticsModel"
      >
        <div class="subtitle-2 white--text text-truncate font-weight-bold">
          Create Utility Company
        </div>
      </v-btn>
      <!-- Select Site -->
      <v-toolbar dense :width="toolBarWidth">
        <v-text-field
          v-model="searchTerm"
          hide-details
          append-icon="mdi-magnify"
          single-line
          :placeholder="placeholder"
          @input="handleSearchInput"
          class="ml-3"
        />
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'UtilityCompaniesBar',

  data() {
    return {
      searchTerm: '',
      toolBarHeight: 50,
      toolBarWidth: 995,
      selectedSite: null,
    };
  },

  computed: {
    placeholder() {
      return this.$route.meta.placeholder || 'Search for a Company';
    },

    userCanCreate() {
      return this.$can('create', 'analytics');
    },
    userCanDelete() {
      return this.$can('delete', 'analytics');
    },
    userCanUpdate() {
      return this.$can('update', 'analytics');
    },
  },

  watch: {
    $route() {
      this.clearSearchTerm();
    },
  },

  methods: {
    ...mapMutations({
      setUtilityCompaniesSearchTerm:
        'SitesStore/UtilityCompaniesStore/SET_UTILITY_COMPANY_SEARCH_TERM',
    }),

    handleAddAnalyticsModel() {
      // TODO: This is a temporary solution. This route will be replaced by the correct one, when the domain's refactor is done
      this.$router.push({
        name: 'UtilityCompaniesCreate',
      });
    },

    handleSearchInput(value) {
      this.setUtilityCompaniesSearchTerm(value);
    },

    clearSearchTerm() {
      this.searchTerm = '';
      this.setUtilityCompaniesSearchTerm('');
    },
  },
};
</script>
<style lang="sass" scoped>
.analytics-bar
  position: fixed
  top: 100px
  z-index: 1
</style>
