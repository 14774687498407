//Power Bi Schemas for filters
const ADVANCED_FILTER = 'http://powerbi.com/product/schema#advanced';
const TOPN_FILTER = 'http://powerbi.com/product/schema#topN';
const BASIC_FILTER = 'http://powerbi.com/product/schema#basic';

export default {
  ADVANCED_FILTER,
  TOPN_FILTER,
  BASIC_FILTER,
};
