import API from '@/Core.Service.Domain/Sites/UtilityCompanies/API';

const setAllUtilityCompanies = async ({ commit }) => {
  commit('SET_ALL_UTILITY_COMPANIES_LOADING', true);
  const result = await API.getAllUtilityCompanies();
  const { data } = result;
  commit('SET_ALL_UTILITY_COMPANIES', data);
  commit('SET_ALL_UTILITY_COMPANIES_LOADING', false);
};

const setUtilityCompanyTypes = async ({ commit }) => {
  const result = await API.getUtilityCompanyTypes();
  const { data } = result;
  commit('SET_UTILITY_COMPANY_TYPES', data);
};

export default {
  setAllUtilityCompanies,
  setUtilityCompanyTypes,
};
