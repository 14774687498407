const utilityCompanies = (state) => state.utilityCompanies;
const utilityCompanyTypes = (state) => state.utilityCompanyTypes;
const isUtilityCompaniesLoading = (state) => state.isUtilityCompaniesLoading;
const utilityCompany = (state) => state.utilityCompany;
const utilityCompanySearchTerm = (state) => state.utilityCompanySearchTerm;

export default {
  utilityCompanies,
  utilityCompanyTypes,
  isUtilityCompaniesLoading,
  utilityCompany,
  utilityCompanySearchTerm,
};
