const NonRoutineEventsPortfolio = () =>
  import('@/Core.Service.Domain/Analytics/NonRoutineEvents/Views');
const NRECreateView = () =>
  import('@/modules/analytics/m_and_v/nonroutineevents/_components/Create');
const NREEditView = () =>
  import('@/modules/analytics/m_and_v/nonroutineevents/_components/Edit');
const NREEnergyAdjustmentChart = () =>
  import(
    '@/modules/analytics/m_and_v/nonroutineevents/_components/EnergyAdjustmentChart'
  );

const NonRoutineEventsRoute = {
  path: 'non-routine-events/all',
  name: 'NonRoutineEvents',
  component: NonRoutineEventsPortfolio,
  meta: {
    placeholder: 'Search for a Non-Routine Event',
    analyticsTypeName: 'AnalyticsNonRoutineEvent',
    label: 'Analytics Non-Routine Events',
    buttonLabel: 'New Non-Routine Event',
    toolTip: 'Non-Routine Event',
    permission: 'non-routine-events:create',
  },
};

const NRECreateRoute = {
  path: 'non-routine-events/create-nre/site/:siteId',
  name: 'NRECreate',
  component: NRECreateView,
  meta: { permission: 'non-routine-events:create' },
};

const NREEditRoute = {
  path: 'non-routine-events/edit-nre/:nonRoutineEventId',
  name: 'NREEdit',
  component: NREEditView,
  meta: { permission: 'non-routine-events:update' },
};

const NREEnergyAdjustmentChartRoute = {
  path: 'non-routine-events/energy-adjust-chart-route/nre/:nonRoutineEventId/version/:version',
  name: 'NREEnergyAdjustmentChart',
  component: NREEnergyAdjustmentChart,
  meta: { permission: 'non-routine-events:read' },
};

export {
  NonRoutineEventsRoute,
  NRECreateRoute,
  NREEditRoute,
  NREEnergyAdjustmentChartRoute,
};
