<template>
  <v-row>
    <v-col :cols="showPeriodSelector ? 3 : 6" class="mr-n4">
      <v-text-field
        v-model="hour"
        :label="customLabels.hour"
        type="number"
        outlined
        @input="handleTimeChange"
        :hint="format === '12' ? '12 hour format' : '24 hour format'"
        :persistent-hint="showPersistentHint"
        :rules="[
          (v) => !!v || 'Hour is required',
          (v) =>
            (v >= 0 && v <= (format === '12' ? 12 : 23)) ||
            (format === '12'
              ? 'Hour must be between 0 and 12'
              : 'Hour must be between 0 and 23'),
        ]"
      >
        <template v-slot:prepend v-if="prependIconData.showPrependIcon">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="primary" v-on="on">
                {{ prependIconData.prependIcon }}
              </v-icon>
            </template>
            <span>{{ prependIconData.prependIconText }}</span>
          </v-tooltip>
        </template>
        <template v-slot:append>
          <v-icon color="primary">
            {{ format === '12' ? 'mdi-hours-12' : 'mdi-hours-24' }}
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col :cols="showPeriodSelector ? 3 : 6">
      <v-text-field
        v-model="minutes"
        :label="customLabels.minutes"
        outlined
        @input="handleTimeChange"
        hint="Minutes"
        :persistent-hint="showPersistentHint"
        type="number"
        :rules="[
          (v) => !!v || 'Minutes are required',
          (v) => (v >= 0 && v <= 59) || 'Minutes must be between 0 and 59',
        ]"
      >
        <template v-slot:prepend>
          <h1 class="primary--text mt-n2">:</h1>
        </template>
        <template v-slot:append>
          <v-icon color="primary">mdi-progress-clock</v-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col v-if="showPeriodSelector" cols="2" class="mr-n5">
      <v-select
        v-model="format"
        :items="items"
        label="Hour Format"
        outlined
        @input="handleTimeChange"
      />
    </v-col>
    <v-col cols="2" v-if="showPeriodSelector && format === '12'">
      <v-select
        v-model="period"
        :items="periods"
        outlined
        @input="handleTimeChange"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TimeSelector',
  props: {
    showPeriodSelector: {
      type: Boolean,
      default: true,
    },
    prependIconData: {
      prependIcon: {
        type: String,
        default: 'mdi-clock-start',
      },
      prependIconText: {
        type: String,
        default: 'Start time',
      },
      showPrependIcon: {
        type: Boolean,
        default: false,
      },
    },
    format: {
      type: String,
      default: '24',
    },
    presetHour: {
      type: String,
      default: '',
    },
    presetMinutes: {
      type: String,
      default: '',
    },
    showPersistentHint: {
      type: Boolean,
      default: false,
    },
    customLabels: {
      type: Object,
      default: () => ({
        hour: 'Hour',
        minutes: 'Minutes',
        period: 'Period',
      }),
    },
  },

  watch: {
    presetHour: {
      handler() {
        this.hour = this.presetHour;
      },
      immediate: true,
    },
    presetMinutes: {
      handler() {
        this.minutes = this.presetMinutes;
      },
      immediate: true,
    },
  },

  data() {
    return {
      hour: '',
      minutes: '',
      period: 'AM',
      items: ['24', '12'],
      periods: ['AM', 'PM'],
    };
  },

  methods: {
    handleTimeChange() {
      this.$emit('time-change', {
        hour: this.hour,
        minutes: this.minutes,
        format: this.showPeriodSelector ? this.format : '',
        period: this.format === '12' ? this.period : '',
      });
    },
  },
};
</script>
