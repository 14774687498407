<template>
  <div>
    <v-btn
      v-if="isComponentMounted"
      color="primary"
      fab
      outlined
      :style="getButtonStyleLeft"
      class="toggle-button"
      @click.stop="setLateraMenuOpen(!mini)"
    >
      <v-icon color="primary" small>{{
        mini ? `mdi-chevron-right` : `mdi-chevron-left`
      }}</v-icon>
    </v-btn>
    <v-navigation-drawer
      app
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      color="primary"
      :width="notMiniWidth"
      :mini-variant-width="miniWidth"
      :transitionend="(drawerWidth = mini ? miniWidth : notMiniWidth)"
    >
      <v-list>
        <MenuLinks v-if="isComponentMounted" :items="items" :mini="mini">
          <template v-slot:admin-menu>
            <AdminMenu
              :onClickParent="onClickParent"
              :style="mini ? 'margin-left: 0' : 'margin-left: -5px'"
              :adminData="{ userPicture, userName }"
              @update:onClickParent="onClickParent = $event"
            />
          </template>
        </MenuLinks>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-list-item link>
            <v-list-item-icon class="mb-8" v-if="mini">
              <v-btn color="aliceBlue" fab x-small>
                <v-icon dark> mdi-email-outline </v-icon>
              </v-btn>
            </v-list-item-icon>

            <v-menu transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-list-item-content class="pl-7 mb-3" v-on="on">
                  <v-btn
                    rounded
                    color="aliceBlue"
                    max-width="140px"
                    @click="handleContactUs"
                  >
                    <div v-if="renderContactLabel">
                      <v-icon left>mdi-email-outline</v-icon>
                      <span class="font-weight-bold primary--text">
                        Contact Us
                      </span>
                    </div>
                  </v-btn>
                </v-list-item-content>
              </template>
            </v-menu>
          </v-list-item>
        </v-list>
        <v-card
          flat
          color="primary"
          class="d-flex justify-center pb-3"
          v-if="!smallBreakpoint"
        >
          <span class="text-caption white--text">
            {{ appVersion }}
          </span>
        </v-card>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import AdminMenu from '@/Core.UI.Domain/Components/AdminMenu.vue';
import MenuLinks from '@/Core.UI.Domain/Components/MenuLinks.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LateralMenuVue',

  components: {
    AdminMenu,
    MenuLinks,
  },

  computed: {
    ...mapGetters({
      userName: 'session/jwtName',
      userPicture: 'session/jwtPicture',
      mini: 'UIStore/getLateraMenuOpen',
    }),

    getButtonStyleLeft() {
      return `left: ${this.drawerWidth - 25}px;`;
    },

    appVersion() {
      // eslint-disable-next-line no-undef
      return VITE_APP_VERSION;
    },

    showAdminMenu() {
      return this.$can('administer', 'control');
    },

    miniWidth() {
      return this.smallBreakpoint ? 68 : 98;
    },
  },

  watch: {
    mini(val) {
      setTimeout(() => {
        this.renderContactLabel = !val;
      }, 100);
    },
    smallBreakpoint(val) {
      this.setLateraMenuOpen(val);
    },
  },

  data(instance) {
    return {
      onClickParent: false,
      drawer: true,
      renderContactLabel: true,
      isComponentMounted: false,
      drawerWidth: 0,
      notMiniWidth: 229,
      items: [
        {
          title: 'Portfolio',
          icon: 'mdi-folder',
          to: '/',
          active: () => true,
          items: [
            {
              icon: 'mdi-message-badge',
              title: 'Notifications',
              to: {
                name: 'AllNotifications',
              },
            },
          ],
        },
        {
          icon: 'mdi-tune-vertical-variant',
          items: [
            {
              icon: 'mdi-thermometer',
              title: 'Thermostats',
              to: { name: 'Thermostats' },
            },
            {
              icon: 'mdi-power-settings',
              title: 'Power Controls',
              to: { name: 'PowerControls' },
            },
          ],
          title: 'Controls',
          to: '',
          active: () => true,
        },
        {
          title: 'Reports',
          icon: 'mdi-file-chart-outline',
          to: '/reports',
          active: () => true,
          items: [
            {
              title: 'Scheduled Reports',
              icon: 'mdi-invoice-text-clock-outline',
              to: { name: 'ScheduledReports' },
              active: () => instance.$can('read', 'scheduled-reports'),
            },
          ],
        },
        {
          title: 'Strategies',
          to: '',
          active: () => this.$can('read', 'strategies'),
          icon: 'mdi-head-lightbulb-outline',
          items: [
            {
              icon: 'mdi-strategy',
              title: 'Strategy',
              to: { name: 'StrategiesCommon' },
              active: () => true,
            },
            {
              icon: 'mdi-lightbulb-multiple-outline',
              title: 'Strategy Templates',
              to: { name: 'StrategyTemplates' },
              active: () => instance.$can('read', 'strategy-templates'),
            },
          ],
        },
        {
          icon: 'mdi-cog-box',
          title: 'Admin Menu',
          to: '',
          active: () => instance.showAdminMenu,
          action: () => {
            instance.onClickParent = !instance.onClickParent;
          },
        },
      ],
    };
  },

  mounted() {
    this.drawerWidth = this.miniWidth;
    this.isComponentMounted = true;
    if (this.smallBreakpoint) {
      this.setLateraMenuOpen(true);
    }
  },

  methods: {
    ...mapActions({
      setLateraMenuOpen: 'UIStore/setLateraMenuOpen',
    }),

    handleContactUs() {
      this.$router.push({ name: 'ContactForm' });
    },
  },
};
</script>

<style lang="sass">
.toggle-button
  border-width: 10px
  position: fixed
  top: 60px
  margin-left: -7px
  background-color: aliceBlue
  transition: left 0.1s ease-in
  z-index: 9

.v-dialog__content
  .v-bottom-sheet
    overflow-y: scroll
</style>
