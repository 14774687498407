const getReportSearchTerm = (state) => state.reportSearchTerm;
const getScheduledReportsList = (state) => state.scheduledReportsList;
const getIsScheduledReportsListLoading = (state) =>
  state.isScheduledReportsListLoading;

const getReportInfo = (state) => state.reportInfo;

const getReportType = (state) => state.reportType;

export default {
  getReportSearchTerm,
  getScheduledReportsList,
  getIsScheduledReportsListLoading,
  getReportInfo,
  getReportType,
};
