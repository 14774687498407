//Power Details
//Power Heatmap
//Site Slicer - vwSite - SiteName
//Electricity Date Slicer - vwReportingDateTime - Date
//Billing

import embed from '../embedReports/dashboardUtilityElectricityEmbed.js';
import slicer from '../slicerReports/dashboardUtilityElectricitySlicer.js';

import { getFunctionAndFileName } from '@/Core.Patterns/Factory';

const embedAndSlice = async function (container, embedConfiguration) {
    const pbiEmbed = await embed.getEmbed(container, embedConfiguration);
    try {
      const visuals = await getVisuals(pbiEmbed);
      // Retrieve the target visuals.
      await slicer.setElectricitySlicers(
        embedConfiguration.reportParameters,
        visuals
      );
    } catch (error) {
      const { functionName, fileName } = getFunctionAndFileName(error);
      console.error(`Error in ${functionName} in ${fileName} =>`, { error });
    }
  },
  getVisuals = async function (pbiEmbed) {
    const pages = await pbiEmbed.getPages();
    // Retrieve the active page.
    const page = pages.filter(function (page) {
      return page.isActive;
    })[0];

    return await page.getVisuals();
  };

export default {
  embedAndSlice,
};
