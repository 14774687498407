const apiVersion = 'v1';

const getAllUtilityCompanies = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/utilitycompanies`);
    const { data } = result;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUtilityCompanyTypes = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/utilitycompanytypes`);
    const { data } = result;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const createUtilityCompany = async (utilityCompany) => {
  try {
    const result = await window.axios.post(
      `/${apiVersion}/utilitycompanies`,
      utilityCompany
    );
    const { data } = result;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const editUtilityCompany = async (utilityCompany) => {
  try {
    const result = await window.axios.put(
      `/${apiVersion}/utilitycompanies`,
      utilityCompany
    );
    const { data } = result;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteUtilityCompany = async (utilityCompanyId) => {
  try {
    const result = await window.axios.delete(
      `/${apiVersion}/utilitycompanies/${utilityCompanyId}`
    );
    const { data } = result;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getAllUtilityCompanies,
  getUtilityCompanyTypes,
  createUtilityCompany,
  editUtilityCompany,
  deleteUtilityCompany,
};
