<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="scheduledReportsList"
      :items-per-page="10"
      :loading="isScheduledReportsListLoading"
      :no-data-text="
        !scheduledReportsList.length ? 'No scheduled reports found' : ''
      "
      :header-props="headerProps"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <!--Headers slot-->
      <template v-slot:headers="props">
        <tr>
          <th
            v-for="(header, i) in props.headers"
            :key="i"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <!--Items slot-->
      <template v-slot:item="{ item }">
        <tr>
          <!--Name slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.report.name }}
            </span>
          </td>
          <!--Site slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.site.name }}
            </span>
          </td>
          <!--Description slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.schedule.description }}
            </span>
          </td>
          <!--Frequency slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ mapFrequency(item.schedule.frequency) }}
            </span>
          </td>
          <!--Time slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{ item.schedule.time }}
            </span>
          </td>
          <!--Day slot-->
          <td>
            <span class="primary--text text-subtitle-1">
              {{
                mapDayUponFrequency(item.schedule.frequency, item.schedule.day)
              }}
            </span>
          </td>
          <!--Actions buttons slot-->
          <td>
            <ActionButtonsVue
              :buttonsList="actionButtons"
              :outlined="true"
              :disabled="!userCanUpdate || !userCanCreate || !userCanDelete"
              :item="item"
              class="ml-n2"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--Confirm Dialog-->
    <ConfirmDialogDeleteScheduledReports
      v-if="showConfirmDialog"
      :dialog="showConfirmDialog"
      :scheduledReportInfo="ScheduledReportInfo"
      @update:dialog="(value) => (showConfirmDialog = value)"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import ConfirmDialogDeleteScheduledReports from '@/Core.Service.Domain/ViewSupport/Reports/Components/ConfirmDialogDeleteScheduledReports.vue';

export default {
  name: 'ScheduledReportsList',

  components: {
    ActionButtonsVue,
    ConfirmDialogDeleteScheduledReports,
  },

  computed: {
    ...mapGetters({
      searchTerm: 'ViewSupportStore/ReportsStore/getReportSearchTerm',
      ScheduledReportsList:
        'ViewSupportStore/ReportsStore/getScheduledReportsList',
      isScheduledReportsListLoading:
        'ViewSupportStore/ReportsStore/getIsScheduledReportsListLoading',
    }),

    scheduledReportsList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems)
        : sortAlphabetically(this.ScheduledReportsList, 'report.name');
    },

    userCanCreate() {
      return this.$can('create', 'analytics');
    },
    userCanDelete() {
      return this.$can('delete', 'analytics');
    },
    userCanUpdate() {
      return this.$can('update', 'analytics');
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['report.name', 'site.name'],
        this.ScheduledReportsList
      );
    },
  },

  data(instance) {
    return {
      filteredItems: [],
      showConfirmDialog: false,
      ScheduledReportInfo: {},
      headerProps: {
        'sort-icon': 'mdi-unfold-more-horizontal',
      },
      headers: [
        {
          text: 'Name',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'report.name',
          sortable: true,
        },
        {
          text: 'Site',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'site.name',
          sortable: true,
        },
        {
          text: 'Schedule',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'schedule.description',
          sortable: true,
        },
        {
          text: 'Frequency',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'schedule.frequency',
          sortable: true,
        },
        {
          text: 'Time',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'schedule.time',
          sortable: true,
        },
        {
          text: 'Day',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'schedule.day',
          sortable: true,
        },
        {
          text: 'Actions',
          align: 'start',
          class: 'primary--text text-subtitle-1 font-weight-bold',
          value: 'actions',
          sortable: false,
        },
      ],
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },
      actionButtons: [
        {
          icon: 'pencil',
          toolTip: 'Edit Scheduled Report',
          action: (item) => {
            const { id } = item;
            if (
              !instance.userCanUpdate ||
              !instance.userCanCreate ||
              !instance.userCanDelete
            )
              return;
            instance.$router.push({
              name: 'EditScheduledReport',
              params: {
                id,
              },
            });
          },
        },
        {
          icon: 'trash-can-outline',
          toolTip: 'Delete Scheduled Report',
          action: (item) => {
            if (
              !instance.userCanUpdate ||
              !instance.userCanCreate ||
              !instance.userCanDelete
            )
              return;
            instance.ScheduledReportInfo = item;
            instance.showConfirmDialog = true;
          },
        },
      ],
      weekDays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
    };
  },

  methods: {
    handleAssignModel() {
      if (!this.userCanUpdate || !this.userCanCreate || !this.userCanDelete)
        return;
      this.$router.push({
        name: 'Models',
      });
    },

    handleAssignedModel(id) {
      if (!this.userCanUpdate || !this.userCanCreate || !this.userCanDelete)
        return;
      this.$router.push({
        name: 'ModelEdit',
        params: {
          id,
        },
      });
    },

    mapFrequency(frequency) {
      switch (frequency) {
        case 1:
          return 'Daily';
        case 2:
          return 'Weekly';
        case 3:
          return 'Monthly';
        default:
          return frequency;
      }
    },

    mapDayUponFrequency(frequency, day) {
      if (frequency === 1) {
        return 'Daily';
      }
      if (frequency === 2) {
        return this.weekDays[day];
      }
      return this.returnDayAsOrdinal(day);
    },

    returnDayAsOrdinal(day) {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    },
  },
};
</script>
