<template>
  <v-banner two-line class="ml-10 rounded-lg" elevation="2" outlined>
    <template v-slot:icon>
      <v-avatar color="red accent-4" size="40">
        <v-icon icon="mdi-alert" color="white"> mdi-alert </v-icon>
      </v-avatar>
    </template>

    <span class="text-subtitle-1 primary--text">
      Dear Customers:
      <strong>We are running a system update today.</strong> You might
      experience some services outages. We apologize for the inconvenience.
    </span>
  </v-banner>
</template>
<script>
export default {
  name: 'WarningBanner',
};
</script>
