import DemandResponseView from '@/Core.Service.Domain/Strategies/DemandResponse/Views/Index.vue';

const DemandResponseRoutes = {
  path: 'demand-response',
  name: 'DemandResponse',
  component: DemandResponseView,
  meta: {
    // permission: 'demand-response:read',
    label: 'Demand Response',
    toolTip: 'Demand Response',
  },
};

export default DemandResponseRoutes;
