<template>
  <div class="image-container">
    <ImageView class="image-view" />
    <!-- Define each hotspot with unique positioning and responsive size -->

    <div v-for="drawing in drawings" :key="drawing.id">
      <div
        class="chip"
        v-if="showDrawingTooltip(drawing)"
        :style="{
          top: drawing.chipTop,
          left: drawing.chipLeft,
          backgroundColor: drawing.color,
          zIndex: 100,
        }"
      >
        <span>
          {{ drawing.occupancyFactor }}
        </span>
      </div>

      <component
        class="hotspot"
        :is="drawing.id"
        :style="{
          top: drawing.top,
          left: drawing.left,
          width: drawing.width,
          height: drawing.height,
        }"
        :color="drawing.color"
        :isActive="drawing.isActive"
        @mouseenter="showTooltip(drawing.id)"
        @mouseleave="hideTooltip"
        @mousemove="updateTooltipPosition"
      />
      <div
        v-if="activeTooltip === drawing.id"
        :style="{
          top: tooltipPosition.top,
          left: tooltipPosition.left,
          zIndex: 101,
        }"
        class="tooltip"
      >
        <!-- Tooltip content -->
        <DigitalTwinsTooltip :color="drawing.color">
          <template v-slot:default>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="primary--text text-subtitle-2 font-weight-bold">
                      Building
                    </th>
                    <th class="primary--text text-subtitle-2 font-weight-bold">
                      Size (est.)
                    </th>
                    <th class="primary--text text-subtitle-2 font-weight-bold">
                      Annual kWh
                    </th>
                    <th class="primary--text text-subtitle-2 font-weight-bold">
                      kWh/SF
                    </th>
                    <th class="primary--text text-subtitle-2 font-weight-bold">
                      Occupancy Factor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="primary--text">
                      {{ drawing.building }}
                    </td>
                    <td class="primary--text">
                      {{ drawing.size }}
                    </td>
                    <td class="primary--text">
                      {{ drawing.annualKwh }}
                    </td>
                    <td class="primary--text">
                      {{ drawing.kWhSF }}
                    </td>
                    <td class="primary--text">
                      {{ drawing.occupancyFactor }}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th class="primary--text text-subtitle-2 font-weight-bold">
                      End Uses
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="primary--text">
                      {{ drawing.endUses }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </DigitalTwinsTooltip>
      </div>
    </div>
  </div>
</template>

<script>
import ImageView from '@/Core.Service.Domain/Controls/DigitalTwins/Components/Image.vue';
import b1Drawing from '../Components/b1Drawing.vue';
import b2Drawing from '../Components/b2Drawing.vue';
import b2ADrawing from '../Components/b2ADrawing.vue';
import b3Drawing from '../Components/b3Drawing.vue';
import b4Drawing from '../Components/b4Drawing.vue';
import b4BDrawing from '../Components/b4BDrawing.vue';
import b4EDrawing from '../Components/b4EDrawing.vue';
import b5Drawing from '../Components/b5Drawing.vue';
import b6Drawing from '../Components/b6Drawing.vue';
import b6ADrawing from '../Components/b6ADrawing.vue';
import b7Drawing from '../Components/b7Drawing.vue';
import b7ADrawing from '../Components/b7ADrawing.vue';
import b7BDrawing from '../Components/b7BDrawing.vue';
import b7DDrawing from '../Components/b7DDrawing.vue';
import b8Drawing from '../Components/b8Drawing.vue';
import b9Drawing from '../Components/b9Drawing.vue';
import b10Drawing from '../Components/b10Drawing.vue';
import b11Drawing from '../Components/b11Drawing.vue';
import b12Drawing from '../Components/b12Drawing.vue';
import b13Drawing from '../Components/b13Drawing.vue';
import b20Drawing from '../Components/b20Drawing.vue';
import bADrawing from '../Components/bADrawing.vue';

import DigitalTwinsTooltip from '@/Core.Service.Domain/Controls/DigitalTwins/Components/DigitalTwinsTooltip.vue';

export default {
  name: 'DigitalTwinsView',
  components: {
    ImageView,
    b1Drawing,
    b2Drawing,
    b2ADrawing,
    b3Drawing,
    b4Drawing,
    b4BDrawing,
    b4EDrawing,
    b5Drawing,
    b6Drawing,
    b6ADrawing,
    b7Drawing,
    b7ADrawing,
    b7BDrawing,
    b7DDrawing,
    b8Drawing,
    b9Drawing,
    b10Drawing,
    b11Drawing,
    b12Drawing,
    b13Drawing,
    b20Drawing,
    bADrawing,
    DigitalTwinsTooltip,
  },

  data() {
    return {
      activeTooltip: null,
      tooltipPosition: {
        top: 0,
        left: 0,
      },
      drawings: [
        {
          id: 'b1Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '50.7%',
          left: '50.56%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '20.5%',
        },
        {
          id: 'b2Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '81.25%',
          left: '50.56%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '20.9%',
        },
        {
          id: 'b2ADrawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '87%',
          left: '54.18%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '10.2%',
        },
        {
          id: 'b3Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '81.25%',
          left: '57.5%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '20.9%',
        },
        {
          id: 'b4Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '47.3%',
          left: '53.85%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '2.7%',
        },
        {
          id: 'b4BDrawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '54.1%',
          left: '54.89%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '2.63%',
        },
        {
          id: 'b4EDrawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '51.42%',
          left: '54.96%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '2.95%',
        },
        {
          id: 'b5Drawing',
          building: '5',
          size: '106,516',
          annualKwh: '2,162,275',
          kWhSF: '20.3',
          occupancyFactor: '53%',
          endUses: 'Substations 5-1',
          top: '50.6%',
          left: '37.75%',
          chipTop: '50%',
          chipLeft: '36.7%',
          width: '49%',
          height: '24%',
          color: 'orange',
        },
        {
          id: 'b6Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '46.4%',
          left: '30.8%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '15.3%',
        },
        {
          id: 'b6ADrawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '45.25%',
          left: '33.7%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '11.2%',
        },
        {
          id: 'b7Drawing',
          building: '7',
          size: '27,867',
          annualKwh: '596,354',
          kWhSF: '21.4',
          occupancyFactor: '47%',
          endUses: 'Substations 7-1, 7-2, 7-3',
          top: '51.8%',
          left: '22.95%',
          chipTop: '50%',
          chipLeft: '23%',
          width: '7.5%',
          height: '25.8%',
          color: 'red',
        },
        {
          id: 'b7ADrawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '59%',
          left: '20.8%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '8%',
        },
        {
          id: 'b7BDrawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '44.9%',
          left: '27.8%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '11.8%',
        },
        {
          id: 'b7DDrawing',
          building: '7D',
          size: '6,124',
          annualKwh: '156,890',
          kWhSF: 'N/A',
          occupancyFactor: 'N/A',
          endUses: 'Substations 7D-1, 7D-2',
          top: '57.75%',
          left: '14.75%',
          chipTop: '57%',
          chipLeft: '13.8%',
          height: '5.29%',
          color: 'purple',
        },
        {
          id: 'b8Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '44.8%',
          left: '16.5%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '15.7%',
        },
        {
          id: 'b9Drawing',
          building: '9',
          size: '127,716',
          annualKwh: '2,388,289',
          kWhSF: '18.7',
          occupancyFactor: '64%',
          endUses: 'Substations 9-2, 9-3, 9-4, 9-5',
          top: '56.5%',
          left: '5.8%',
          chipTop: '55%',
          chipLeft: '5.4%',
          width: '40.5%',
          height: '43.5%',
          color: 'green',
        },
        {
          id: 'b10Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '28.7%',
          left: '39.2%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '22%',
        },
        {
          id: 'b11Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '46.8%',
          left: '46.8%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '32%',
        },
        {
          id: 'b12Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '26.3%',
          left: '6.15%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '8.3%',
        },
        {
          id: 'b13Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '60.25%',
          left: '64.1%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '8.68%',
        },
        {
          id: 'b20Drawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '28.3%',
          left: '24.47%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '20.85%',
        },
        {
          id: 'bADrawing',
          isActive: false,
          showChip: false,
          building: '',
          size: '',
          annualKwh: '',
          kWhSF: '',
          occupancyFactor: '',
          endUses: '',
          top: '19.78%',
          left: '54.4%',
          chipTop: '56.5%',
          chipLeft: '14%',
          height: '17.9%',
        },
      ],
    };
  },

  methods: {
    showTooltip(hotspotId) {
      this.activeTooltip = hotspotId;
    },
    hideTooltip() {
      this.activeTooltip = null;
    },
    updateTooltipPosition(event) {
      this.tooltipPosition = {
        top: `${event.clientY + window.scrollY + 50}px`, // Adjust for vertical scroll
        left: `${event.clientX + window.scrollX + 50}px`, // Adjust for horizontal scroll
      };
    },
    showDrawingTooltip(hotspotId) {
      if (hotspotId.showChip === undefined) {
        return true;
      } else {
        return hotspotId.showChip;
      }
    },
  },
};
</script>
<style lang="scss">
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-view {
  width: 100%;
  height: auto;
}

.hotspot {
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.tooltip {
  position: absolute;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  white-space: nowrap;
  pointer-events: none;
  transform: translate(-50%, -100%);
}

.chip {
  position: absolute;
  padding: 5px 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
  span {
    font-size: 20px !important;
    color: white;
    font-weight: bold;
  }
}
</style>
