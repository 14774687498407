<template>
  <section>
    <PowerBIReport
      :reportName="reportName"
      :siteId="siteId"
      :controlFriendlyName="controlFriendlyName"
      ref="powerBIReport"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import PowerBIReport from './PowerBIReport';

export default {
  name: 'ReportDetailsModule',
  data() {
    return {
      report: null,
      reportName: this.$route.params.reportName,
      siteId: this.$route.params.siteId?.toString(),
      controlFriendlyName: this.$route.params?.controlFriendlyName,
    };
  },

  components: {
    PowerBIReport,
  },

  computed: {
    ...mapGetters({
      userId: 'session/jwtSubject',
      permissions: 'session/userPermissions',
    }),
  },
};
</script>
