<template>
  <div>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>
        <span class="text-subtitle-1">
          {{ StrategyTodaysHistory.deviceLabel }}
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card class="list-card mx-auto">
      <v-expansion-panels accordion flat tile>
        <v-expansion-panel
          v-for="(history, index) in paginatedHistory"
          :key="index"
          no-action
        >
          <v-expansion-panel-header>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div style="max-width: 20%" v-bind="attrs" v-on="on">
                  <span class="font-weight-bold primary--text">
                    {{ new Date(Date.parse(history.date)).toLocaleString() }}
                  </span>
                </div>
              </template>
              <span> UTC: {{ history.date }} </span>
            </v-tooltip>

            <v-btn
              v-if="history.error"
              plain
              class="acc-btn mx-2"
              max-width="600"
            >
              <v-icon color="heatColor"> mdi-alert-circle </v-icon>
              <span
                class="ml-2 d-inline-block text-truncate font-weight-bold primary--text"
                style="max-width: 500px"
              >
                {{ history.error }}
              </span>
            </v-btn>

            <v-btn
              v-if="history.actionDetails"
              plain
              class="acc-btn mx-2"
              max-width="600"
            >
              <v-icon color="greenGraph"> mdi-check-circle </v-icon>
              <span
                class="ml-2 d-inline-block text-truncate font-weight-bold primary--text"
                style="max-width: 500px"
              >
                {{ history.action }}
              </span>
            </v-btn>

            <template v-slot:actions>
              <v-btn depressed fab x-small active-class="sonicSilver">
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-btn
                  plain
                  class="acc-btn"
                  max-width="600"
                  @click="handleHistoryDetails(history)"
                >
                  <v-icon> mdi-cogs </v-icon>
                  <span
                    class="ml-2 d-inline-block text-truncate font-weight-bold primary--text"
                    style="max-width: 500px"
                  >
                    See more details
                  </span>
                </v-btn>
                <hr />
              </v-col>
            </v-row>
          </v-expansion-panel-content>

          <v-expansion-panel-content v-for="(value, key) in history" :key="key">
            <v-row v-if="value && key !== 'date'">
              <v-col cols="4">
                <span class="primary--text font-weight-bold">
                  {{ splitCamelCase(key) }}:
                </span>
                <hr />
              </v-col>
              <v-col cols="8">
                <table v-if="isDevice(key)">
                  <tr
                    v-for="(deviceValue, deviceKey) in value"
                    :key="deviceKey"
                  >
                    <td>
                      <span class="primary--text">
                        {{ `${splitCamelCase(deviceKey)}: ${deviceValue}` }}
                      </span>
                    </td>
                  </tr>
                </table>
                <span v-else class="primary--text">
                  {{ mapValueByKey(value, key) }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-pagination
      v-if="removeKeysFromHistory.length > itemsPerPage"
      v-model="currentPage"
      :length="Math.ceil(removeKeysFromHistory.length / itemsPerPage)"
      color="primary"
    />
  </div>
</template>
<script>
import { splitCamelCase, removeObjectKeys } from '@/Core.Patterns/Factory';
export default {
  name: 'StrategyExecutionHistoryCard',

  props: {
    StrategyTodaysHistory: {
      type: Object,
      required: true,
    },
  },

  computed: {
    removeKeysFromHistory() {
      // const keysToRemove = ['logId'];

      const history = [...this.StrategyTodaysHistory.history];

      // return history.map((historyItem) =>
      //   removeObjectKeys(historyItem, keysToRemove)
      // );
      return history;
    },

    paginatedHistory() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.removeKeysFromHistory.slice(start, end);
    },
  },

  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
    };
  },

  methods: {
    splitCamelCase,

    isDevice(key) {
      return key === 'device';
    },

    mapValueByKey(value, key) {
      if (key === 'actionDetails') {
        const keysToRemove = [
          'SiteId',
          'StrategyId',
          'StrategyName',
          'StrategyRunDateTime',
          'ControlId',
        ];
        return Object.entries(removeObjectKeys(JSON.parse(value), keysToRemove))
          .map(([key, value]) => `${splitCamelCase(key)}: ${value}`)
          .join(', ');
      } else {
        return value;
      }
    },

    handleHistoryDetails(history) {
      const { strategyName, strategyId } = this.$route.params;
      this.$store.commit('StrategiesStore/StrategiesStore/STRATEGY_INFO', {
        strategyName,
        strategyId,
      });
      const { logId } = history;
      this.$router.push({
        name: 'StrategyExecutionHistoryReplay',
        params: {
          logId,
        },
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.list-card
  width: 100%
  max-height: 650px
  overflow-y: scroll

.acc-btn
  justify-content: flex-start

hr
  margin: 0.5rem 0
  border: 0
  border-top: 1px solid rgba(0, 0, 0, 0.2)
</style>
