<template>
  <v-dialog v-model="dialogValue" persistent max-width="290">
    <v-card>
      <v-card-title class="primary--text text-h5">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text class="primary--text">
        {{ dialogConfirm }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-pill"
          color="primary"
          depressed
          @click="dialogValue = false"
        >
          <div
            class="subtitle-2 white--text text-truncate font-weight-bold px-3"
          >
            Cancel
          </div>
        </v-btn>
        <v-btn
          class="rounded-pill"
          color="warning"
          depressed
          :loading="loadingDelete"
          :disabled="loadingDelete"
          @click="handleDelete"
        >
          <div
            class="subtitle-2 white--text text-truncate font-weight-bold px-3"
          >
            Delete
          </div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/Core.Service.Domain/Monitors/Piera/API'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ConfirmDialogDeletePieraMonitor',

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    PieraMonitorInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingDelete: false,
    }
  },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
    }),

    dialogValue: {
      get() {
        return this.dialog
      },
      set(value) {
        this.$emit('update:dialog', value)
      },
    },

    dialogTitle() {
      return `Delete this ${this.$route.meta.toolTip}?`
    },

    dialogConfirm() {
      return `Are you sure you want to delete ${this.PieraMonitorInfo.friendlyName} and its associated data? Deleting a ${this.$route.meta.toolTip} cannot be undone.`
    },
  },

  methods: {
    ...mapActions({
      getPieraMonitorsBySiteId:
        'MonitorsStore/PieraMonitorsStore/getPieraMonitorsBySiteId',
    }),

    async handleDelete() {
      try {
        this.loadingDelete = true
        await API.deletePieraMonitor(this.PieraMonitorInfo)
        await this.getPieraMonitorsBySiteId({
          selectedSite: this.defaultSite,
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.dialogValue = false
        this.loadingDelete = false
      }
    },
  },
}
</script>
