import constants from '../constants';

let firstSiteName = '';

const setSlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setDateSlicerVisual(reportParameters, visuals);
  await setEndUseSlicerVisual(reportParameters, visuals);
};
const setSiteSlicerVisual = async function (reportParameters, visuals) {
  const siteSlicerFilter = {
    $schema: constants.ADVANCED_FILTER,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };
  const siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
  }
};

const setDateSlicerVisual = async function (reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: constants.ADVANCED_FILTER,
    target: {
      table: 'vwEndUseOneMinDirectQuery',
      column: 'SiteDateTimeOffset',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };

  const dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Slicer';
  })[0];

  if (dateSlicerVisual) {
    setEndUseSlicerVisual(reportParameters, visuals);
    // Next lines will console the table and column from the slicer visual.
    // const state = await dateSlicerVisual.getSlicerState();
    // console.log('date state', state);
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
  }
};

const setEndUseSlicerVisual = async function (reportParameters, visuals) {
  try {
    const siteData = getSiteDataBySiteName(reportParameters);
    const { SiteName } = reportParameters;

    const endUseSlicerVisual = visuals.filter(function (visual) {
      return visual.type === 'slicer' && visual.title === 'End Use Slicer';
    })[0];
    // Next lines will console the table and column from the slicer visual.
    // const state = await endUseSlicerVisual.getSlicerState();
    // console.log('date state', state);

    if (!endUseSlicerVisual) {
      console.error('End Use Slicer visual not found');
      return;
    }

    const values = siteData ? [siteData.EndUseName] : ['No Data to show'];

    if (!SiteName) {
      try {
        const endUseSlicerState = await endUseSlicerVisual.getSlicerState();
        await endUseSlicerVisual.setSlicerState(endUseSlicerState);
      } catch (error) {
        console.error('Error fetching or setting slicer state:', error);
      }
    } else {
      await endUseSlicerVisual.setSlicerState({
        filters: [
          {
            $schema: constants.BASIC_FILTER,
            target: {
              table: 'vwEndUseNames',
              column: 'EndUseName',
            },
            operator: 'In',
            values,
          },
        ],
      });
    }
  } catch (error) {
    console.error('Error setting slicer state:', error);
  }
};

/**
 * Retrieves the first site data based on the SiteName parameter.
 * @param {Object} reportParameters - The report parameters.
 * @param {String} uomName - The UOM name.
 * @returns {Array} - The first site data.
 */
const getSiteDataBySiteName = (reportParameters) => {
  const { SiteName, SiteEndUses } = reportParameters;
  if (SiteEndUses) {
    const parsedData = JSON.parse(SiteEndUses);

    const trimmedSiteName = firstSiteName.length
      ? firstSiteName
      : SiteName.trim().toLowerCase();

    const response = parsedData.find((data) => {
      return data.SiteName.trim().toLowerCase() === trimmedSiteName;
    });

    return !response ? parsedData[0] : response;
  }

  return null;
};

export default {
  setSlicers,
  setDateSlicerVisual,
  setEndUseSlicerVisual,
};
