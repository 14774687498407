<template>
  <v-container>
    <v-card width="50%">
      <v-card-title>
        <span class="primary--text"> Create Demand Response Event </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedSites"
              :loading="isLoadingConfig"
              :disabled="isLoadingConfig"
              :items="sitesList"
              :item-text="(item) => item.name"
              :item-value="(item) => item.id"
              :menu-props="{ maxHeight: '400' }"
              label="Sites"
              multiple
              outlined
              hint="Select one or more sites the event applies to"
              persistent-hint
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-select
              v-model="selectedEvent"
              :loading="isLoadingConfig"
              :disabled="isLoadingConfig"
              :items="eventTypes"
              :item-text="(item) => item.name"
              :item-value="(item) => item.key"
              :menu-props="{ maxHeight: '400' }"
              label="Type of Event"
              outlined
              hint="Select the type of event"
              persistent-hint
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="eventValue"
              outlined
              :items="eventValues"
              label="Event Value"
              hint="Select the event value"
            />
            <!--
            <v-select
              v-model="selectedLevel"
              :items="levelsAvailable"
              :menu-props="{ maxHeight: '400' }"
              label="Level Value"
              outlined
              hint="Select the level value"
              persistent-hint
            />
          -->
          </v-col>
        </v-row>
        <v-row v-if="showTime">
          <v-col cols="12">
            <span class="primary--text">
              Select the event window, in UTC ({{ timezoneOffsetString }} from
              your current time). Ending-time is optional for "return-to-normal"
              events.
            </span>
          </v-col>
        </v-row>
        <v-row v-if="showTime">
          <v-col cols="6">
            <v-text-field
              label="Event Date"
              v-model="selectedDate"
              type="date"
              color="primary"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-card class="pl-2 mt-n10 mb-3">
              <v-list-item-content>
                <span class="primary--text mb-3">Starting Time</span>
                <TimeSelector
                  :showPeriodSelector="false"
                  :prependIconData="{}"
                  @time-change="
                    (value) =>
                      (selectedStartTime = `${value.hour}:${value.minutes}:00`)
                  "
                />
              </v-list-item-content>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="3">
            <v-card class="pl-2 mt-n9 mb-3">
              <v-list-item-content>
                <span class="primary--text mb-3">Lasting until</span>
                <TimeSelector
                  :showPeriodSelector="false"
                  :prependIconData="{}"
                />
              </v-list-item-content>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12">
            <v-btn
              :loading="isLoading"
              :disabled="isLoading"
              color="primary"
              class="mr-2"
              @click="handleCreateDemandResponse"
            >
              Create
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import TimeSelector from '@/Core.UI.Domain/Components/TimeSelector.vue';
import { isNumber } from '@/Core.Patterns/Factory/validationFactory.js';
import API from '@/Core.Service.Domain/Strategies/DemandResponse/API';

export default {
  name: 'DemandResponse',

  components: {
    TimeSelector,
  },

  async created() {
    this.isLoadingConfig = true;
    try {
      const config = await API.getDemandResponseEventsConfiguration();

      this.sitesList = Object.keys(
        config.sitesWithDemandResponseStrategies
      ).map((k) => ({
        id: k,
        name: config.sitesWithDemandResponseStrategies[k],
      }));
      this.eventTypes = config.eventTypes;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingConfig = false;
    }
  },

  computed: {
    timezoneName() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    timezoneOffsetString() {
      const pad = function (num) {
        return (num < 10 ? '0' : '') + num;
      };
      const tzo = new Date().getTimezoneOffset(),
        tzoSign = tzo >= 0 ? '+' : '-',
        tzoHours = Math.floor(Math.abs(tzo) / 60),
        tzoMinutes = Math.abs(tzo) % 60;
      return `${tzoSign}${pad(tzoHours)}:${pad(tzoMinutes)}`;
    },
  },

  data() {
    return {
      selectedSites: [],
      sitesList: [],
      eventTypes: [],
      eventValue: 0,
      eventValues: [0, 1, 2],
      selectedEvent: '',
      selectedDate: new Date().toISOString().substring(0, 10),
      isLoadingConfig: false,
      isLoading: false,
      showTime: false, // for demo we don't need this
      selectedStartTime: '00:00',
    };
  },

  methods: {
    async handleCreateDemandResponse() {
      this.isLoading = true;
      try {
        await API.createDemandResponseEvent({
          siteIds: this.selectedSites,
          eventType: this.selectedEvent,
          eventValue: this.eventValue,
          startTimeUtc: `${this.selectedDate} ${this.selectedStartTime}`,
        });
        this.$toast.show('Demand Response Event created', null, 'success');
      } catch (error) {
        console.error('handleCreateDemandResponse', error);
        this.$toast.show('Error creating Demand Response Event', null, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    isNumber,
  },
};
</script>
