const apiVersion = 'v1';

const getReports = async () => {
  try {
    const result = await window.axios.get(`/${apiVersion}/viewsupport/report`);

    const { data } = result.data;
    return data;
  } catch (err) {
    return err;
  }
};

const getScheduledReportsList = async () => {
  const result = await window.axios.get(`/${apiVersion}/reports/schedules`);

  const { data } = result.data;

  return data;
};

const getScheduledReports = async () => {
  const result = await window.axios.get(
    `/${apiVersion}/PowerBiReport?type=scheduled`
  );

  const { data } = result.data;
  return data;
};

const getPortfolioSites = async () => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/viewsupport/portfolio/sitedata`
    );
    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const createScheduledReport = async (report) => {
  try {
    const result = await window.axios.post(
      `/${apiVersion}/reports/schedules`,
      report
    );

    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteScheduledReport = async (reportId) => {
  try {
    const result = await window.axios.delete(
      `/${apiVersion}/reports/schedules/${reportId}`
    );

    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getScheduledReportById = async (reportId) => {
  try {
    const result = await window.axios.get(
      `/${apiVersion}/reports/schedules/${reportId}`
    );

    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const editScheduledReport = async (reportId, report) => {
  try {
    const result = await window.axios.put(
      `/${apiVersion}/reports/schedules/${reportId}`,
      report
    );

    const { data } = result.data;
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  getReports,
  getScheduledReportsList,
  getPortfolioSites,
  getScheduledReports,
  createScheduledReport,
  deleteScheduledReport,
  getScheduledReportById,
  editScheduledReport,
};
