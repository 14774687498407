const REPORT_SEARCH_TERM = (state, reportSearchTerm) => {
  state.reportSearchTerm = reportSearchTerm;
};

const SET_SCHEDULED_REPORTS_LIST = (state, scheduledReportsList) => {
  state.scheduledReportsList = scheduledReportsList;
};

const SET_SCHEDULED_REPORTS_LIST_LOADING = (
  state,
  isScheduledReportsListLoading
) => {
  state.isScheduledReportsListLoading = isScheduledReportsListLoading;
};

const SET_REPORT_INFO = (state, reportInfo) => {
  state.reportInfo = reportInfo;
};

const SET_REPORT_TYPE = (state, reportType) => {
  state.reportType = reportType;
};

export default {
  REPORT_SEARCH_TERM,
  SET_SCHEDULED_REPORTS_LIST,
  SET_SCHEDULED_REPORTS_LIST_LOADING,
  SET_REPORT_INFO,
  SET_REPORT_TYPE,
};
