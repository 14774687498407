<template>
  <div>
    <BannerAlertVue
      v-if="!strategyTodaysHistory[0]?.history.length && !isHistoryLoading"
      :message="bannerMessage"
    />
    <StrategyExecutionHistoryList
      :StrategyTodaysHistory="strategyTodaysHistory"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StrategyExecutionHistoryList from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategyExecutionHistoryList.vue';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

export default {
  name: 'StrategiesExecutionHistoryListView',
  components: {
    StrategyExecutionHistoryList,
    BannerAlertVue,
  },
  computed: {
    ...mapGetters({
      strategyTodaysHistory:
        'StrategiesStore/StrategiesStore/getStrategyTodaysHistory',
      isHistoryLoading: 'StrategiesStore/StrategiesStore/getIsHistoryLoading',
    }),

    bannerMessage() {
      return `No history logs for this strategy for this date.`;
    },
  },

  created() {
    this.$store.commit(
      'StrategiesStore/StrategiesStore/SELECTED_SITE_STRATEGIES_IS_LOADING',
      false
    );
  },
};
</script>
