<template>
  <ValidationProvider
    :name="$attrs.label"
    :rules="rules"
    v-slot="{ errors, valid }"
  >
    <v-text-field
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      v-on="$listeners"
      ref="text"
      outlined="outlined"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },

  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      innerValue: '',
    };
  },

  created() {
    this.$validator.attach;
    if (this.value) {
      this.innerValue = this.value;
    }
  },

  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },

    value(newVal) {
      this.innerValue = newVal;
    },
  },
};
</script>
