<template>
  <div>
    <BannerAlertVue
      v-if="!strategiesList.length && !isLoading"
      :message="bannerMessage"
    />
    <StrategiesListVue v-else :StrategiesList="strategiesList" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StrategiesListVue from '@/Core.Service.Domain/Strategies/Strategies/Components/StrategiesList';
import BannerAlertVue from '@/Core.UI.Domain/Components/BannerAlert.vue';

export default {
  name: 'StrategiesListView',
  components: {
    StrategiesListVue,
    BannerAlertVue,
  },
  computed: {
    ...mapGetters({
      strategiesList: 'StrategiesStore/StrategiesStore/selectedSiteStrategies',
      isLoading:
        'StrategiesStore/StrategiesStore/selectedSiteStrategiesIsLoading',
    }),
    bannerMessage() {
      return `No ${this.$route.meta.label} configured for this site`;
    },
  },
};
</script>
