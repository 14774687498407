<template>
  <div class="my-2">
    <v-tooltip
      bottom
      :offset-overflow="toolTipOffsetOverflow"
      v-for="(button, i) in buttonsList"
      :key="`${i}-${generateRandomCustomKey}`"
      :content-class="hasTooltip ? 'control-tooltip' : ''"
    >
      <template
        v-slot:activator="{ on, attrs }"
        v-if="
          'show' in button
            ? typeof button.show === 'function'
              ? button.show()
              : button.show
            : true
        "
      >
        <v-btn
          :depressed="isIcon"
          :color="color"
          fab
          :small="isIcon"
          :x-small="!isIcon"
          class="action-button"
          :outlined="
            outlined || ('outlined' in button ? button.outlined : false)
          "
          :disabled="
            disabled || ('disabled' in button ? button.disabled(item) : false)
          "
          :loading="button.loading ? button.loading(item) : false"
          v-bind="attrs"
          v-blur
          v-on="on"
          @click="() => ('action' in button ? button.action(item) : null)"
        >
          <v-icon :color="outlined ? '' : 'white'">{{
            typeof button.icon === 'function'
              ? `mdi-${button.icon(item)}`
              : `mdi-${button.icon}`
          }}</v-icon>
        </v-btn>
      </template>
      <span>
        {{
          typeof button.toolTip === 'function'
            ? button.toolTip(item)
            : button.toolTip
        }}
      </span>
      <slot name="tooltip"></slot>
    </v-tooltip>
  </div>
</template>

<script>
import { generateRandomCustomKey } from '@/Core.Patterns/Factory';

export default {
  name: 'ActionButtons',

  data: () => ({
    show: true,
  }),

  computed: {
    hasTooltip() {
      return Boolean(this.$slots.tooltip);
    },
  },

  props: {
    buttonsList: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    toolTipOffsetOverflow: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    generateRandomCustomKey,
  },
};
</script>
<style lang="sass" scoped>
.action-button
  margin-left: 12px
  margin-right: 5.6px

.control-tooltip
  opacity: 1!important
  background: none!important
</style>
