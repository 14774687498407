import { Auth0Client } from '@auth0/auth0-spa-js';

import { globalConfig } from '../configuration/config';

const getWebAuth = (dynoConfig) => {
  return new Auth0Client({
    domain: dynoConfig.DYNO_AUTH0_DOMAIN,
    clientId: dynoConfig.DYNO_AUTH0_CLIENTID,
    authorizationParams: {
      scope: 'openid email profile offline_access',
      redirect_uri: import.meta.env.VITE_APP_CALLBACK_URI,
      audience: dynoConfig.DYNO_AUTH0_AUDIENCE,
    },
    useRefreshTokens: true,
    // useRefreshTokensFallback: true,
  });
};

export default class AuthenticationService {
  constructor() {
    this.auth0 = getWebAuth(globalConfig.get());
  }

  async handleAuthentication() {
    try {
      await this.auth0.handleRedirectCallback();
      const isAuthenticated = await this.auth0.isAuthenticated();

      if (isAuthenticated) {
        const user = await this.auth0.getUser();
        const accessToken = await this.auth0.getTokenSilently();

        const userInfo = {
          ...user,
          accessToken,
        };
        return Promise.resolve(userInfo);
      } else {
        return Promise.reject('User not authenticated');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async login() {
    await this.auth0.loginWithRedirect();
  }

  logout() {
    this.auth0.logout();
  }

  parseAuth0Token(token) {
    try {
      // Split the token into its parts
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('Invalid token format');
      }

      // Decode the payload part of the token
      const payload = parts[1];
      const decodedPayload = JSON.parse(atob(payload));

      return decodedPayload;
    } catch (error) {
      console.error('Error parsing token:', error);
      return null;
    }
  }

  async getAuth0RefreshToken() {
    try {
      const token = await this.auth0.getTokenSilently();
      return Promise.resolve(token);
    } catch (e) {
      if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
        return Promise.reject(e);
      }
    }
  }
}
