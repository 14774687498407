<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="1655.48278,1006.69276,157.05882,358.47058"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="isActive ? '0.3' : '0.2'"
      :fill="color"
      fill-rule="nonzero"
      :stroke="color"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
      pointer-events="all"
    >
      <path
        d="M1660.68866,1202.48687v-2.94117h-4.70588v-21.81624h4.70588v-3.65435h4.29412v-166.88235h139.23529v44.70588h7.82353v122.7647h-7.82353v190h-139.23529v-162.17647z"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'b2Drawing',
  props: {
    color: {
      type: String,
      default: '#2E3C54',
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleMouseEnter(event) {
      if (this.isActive) {
        this.$emit('mouseenter', event);
      }
    },
    handleMouseMove(event) {
      if (this.isActive) {
        this.$emit('mousemove', event);
      }
    },
    handleMouseLeave(event) {
      if (this.isActive) {
        this.$emit('mouseleave', event);
      }
    },
  },
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
