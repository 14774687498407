const DaikinThermostatsPortfolio = () =>
  import('@/Core.Service.Domain/Controls/DaikinThermostats/Views');

export const DaikinThermostatsRoute = {
  path: 'daikin-thermostats',
  name: 'DaikinThermostats',
  component: DaikinThermostatsPortfolio,
  children: [
    {
      path: 'history/:id',
      name: 'DaikinThermostatHistory',
    },
  ],
  meta: {
    placeholder: 'Search for a Thermostat',
    controlTypeName: 'DaikinThermostat',
    label: 'Daikin Thermostats',
    toolTip: 'Daikin Thermostat',
  },
};
