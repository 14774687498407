<template>
  <div>
    <!--Settings change Warning-->
    <v-alert
      border="right"
      colored-border
      elevation="2"
      class="primary--text"
      :color="color"
    >
      <v-row>
        <v-col>
          <span class="text-h5">
            {{ tooltipInfo.device?.type }}
          </span>
        </v-col>
      </v-row>
      <slot></slot>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'DigitalTwinsTooltip',

  props: {
    tooltipInfo: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'alert',
    },
  },
};
</script>
<style lang="sass" scoped>
.control-tooltip
  opacity: 1!important
  background: none!important
</style>
