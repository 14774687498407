const ModelsPortfolio = () =>
  import('@/Core.Service.Domain/Analytics/Models/Views');
const ModelCreateView = () =>
  import('@/modules/analytics/analytics_models/_components/Create');
const ModelEditView = () =>
  import('@/modules/analytics/analytics_models/_components/Edit');

const ModelsRoute = {
  path: 'models/all',
  name: 'Models',
  component: ModelsPortfolio,
  meta: {
    placeholder: 'Search for an Analytics Model',
    analyticsTypeName: 'AnalyticsModel',
    label: 'Analytics Models',
    buttonLabel: 'New Analytics Model',
    toolTip: 'Analytics Model',
    permission: 'analytics:read',
  },
};

const ModelCreateRoute = {
  path: 'models/create-model/site/:siteId',
  name: 'ModelCreate',
  component: ModelCreateView,
};

const ModelEditRoute = {
  path: 'models/edit-model/:id',
  name: 'ModelEdit',
  component: ModelEditView,
};

export { ModelCreateRoute, ModelEditRoute, ModelsRoute };

