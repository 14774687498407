import actions from '@/Core.Service.Domain/Sites/UtilityCompanies/Store/actions.js';
import getters from '@/Core.Service.Domain/Sites/UtilityCompanies/Store/getters.js';
import mutations from '@/Core.Service.Domain/Sites/UtilityCompanies/Store/mutations.js';

const state = {
  utilityCompanies: [],
  utilityCompany: {},
  utilityCompanyTypes: [],
  isUtilityCompaniesLoading: false,
  utilityCompanySearchTerm: '',
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
