const SET_ALL_UTILITY_COMPANIES = (state, payload) => {
  state.utilityCompanies = payload;
};

const SET_UTILITY_COMPANY_TYPES = (state, payload) => {
  state.utilityCompanyTypes = payload;
};

const SET_ALL_UTILITY_COMPANIES_LOADING = (state, payload) => {
  state.isUtilityCompaniesLoading = payload;
};

const SET_UTILITY_COMPANY = (state, payload) => {
  state.utilityCompany = payload;
};

const SET_UTILITY_COMPANY_SEARCH_TERM = (state, payload) => {
  state.utilityCompanySearchTerm = payload;
};

export default {
  SET_ALL_UTILITY_COMPANIES,
  SET_UTILITY_COMPANY_TYPES,
  SET_ALL_UTILITY_COMPANIES_LOADING,
  SET_UTILITY_COMPANY,
  SET_UTILITY_COMPANY_SEARCH_TERM,
};
