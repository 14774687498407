import Vue from 'vue';
import HighchartsVue from 'highcharts-vue';
import ToastPlugin from './plugins/toasts';
import AxiosPlugin from './plugins/axios';

import './plugins/vee-validate';
import './plugins/abilities';
import './plugins/vue2filters';
import './plugins/weather-icon-mapper';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { globalConfig } from './configuration/config';
import { datadogRum } from '@datadog/browser-rum';
import { version } from '../package.json';

import VueTheMask from 'vue-the-mask';

const isProduction = import.meta.env.PROD;
const env = import.meta.env.MODE;

Vue.config.productionTip = false;

Vue.use(AxiosPlugin);
Vue.use(ToastPlugin);

Vue.use(HighchartsVue);

Vue.prototype.$appOldVersion = 'v1';

Vue.directive('blur', {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur();
  },
});

Vue.use(VueTheMask);

Vue.mixin({
  computed: {
    smallBreakpoint() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },
});

if (isProduction && globalConfig.get().DYNO_DD_APPLICATION_ID) {
  datadogRum.init({
    applicationId: globalConfig.get().DYNO_DD_APPLICATION_ID,
    clientToken: globalConfig.get().DYNO_DD_CLIENT_ID,
    site: 'us3.datadoghq.com',
    service: 'e360-web-app',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
