<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="557.50682,388.05315,117.66666,746.55556"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      fill-opacity="0.3"
      :fill="color"
      fill-rule="nonzero"
      :stroke="color"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
      pointer-events="all"
    >
      <path
        d="M558.00682,548.44204v-147.66667h20v-12.22222h52.11111v12.22222h44.44444v136.55556h0.11111v596.77778h-85.66667v-585.66667z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'b9Drawing',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleMouseEnter(event) {
      this.$emit('mouseenter', event);
    },
    handleMouseMove(event) {
      this.$emit('mousemove', event);
    },
  },
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
