import embed from '../embedReports/CO2Embed.js';
import slicer from '../slicerReports/CO2Slicer.js';

import { getFunctionAndFileName } from '@/Core.Patterns/Factory';

const embedAndSlice = async (container, embedConfiguration) => {
  const pbiEmbed = embed.getEmbed(container, embedConfiguration);
  try {
    const visuals = await getVisuals(pbiEmbed);
    // Retrieve the target visuals.
    await slicer.setSlicers(embedConfiguration.reportParameters, visuals);
  } catch (error) {
    const { functionName, fileName } = getFunctionAndFileName(error);
    console.error(`Error in ${functionName} in ${fileName} =>`, { error });
  }
};

const getVisuals = async (pbiEmbed) => {
  const pages = await pbiEmbed.getPages();

  // Retrieve the active page.
  const page = pages.filter((page) => {
    return page.isActive;
  })[0];

  return await page.getVisuals();
};

export default {
  embedAndSlice,
};
