<template>
  <v-row v-if="singleLine">
    <v-col
      :class="{ 'font-weight-bold': labelBold, 'subtitle-1': labelLarge }"
      >{{ label }}</v-col
    >
    <v-col>
      <slot>{{ value }}</slot>
    </v-col>
  </v-row>
  <v-row class="mb-2" no-gutters v-else>
    <v-col
      cols="12"
      :class="{ 'font-weight-bold': labelBold, 'subtitle-1': labelLarge }"
      >{{ label }}</v-col
    >
    <v-col cols="12" :class="{ 'ml-4': valueIndent }">
      <slot>{{ value }}</slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LabelVue',
  props: {
    label: { type: String, required: true },
    value: { type: [String, Number] },
    singleLine: { type: Boolean, default: false },
    labelBold: { type: Boolean, default: true },
    valueIndent: { type: Boolean, default: true },
    labelLarge: { type: Boolean, default: false },
  },
  data() {
    return {
      coolSetting: this.control ? this.control.coolSetting : null,
      // system: this.schedule ? this.schedule.systemTypeId : null,
      // fan: this.schedule ? this.schedule.isFanAuto : false,
      locked: this.control ? this.control.isKeyPadLocked : false,
    };
  },
};
</script>
