<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-carousel
    :continuous="true"
    :cycle="cycle"
    :show-arrows-on-hover="true"
    :show-arrows="slides.length > 1"
    hide-delimiters
    height="auto"
  >
    <v-carousel-item v-for="(slide, i) in slides" :key="i">
      <v-card
        class="mx-auto"
        :width="!smallBreakpoint ? 1024 : 350"
        :min-width="!smallBreakpoint ? 500 : 350"
        color="primary"
        min-height="600"
        :max-height="!smallBreakpoint ? 800 : 500"
      >
        <div class="container" v-if="slide.messageCenterMessageTypeId === 3">
          <v-card-title
            :class="{ 'text-subtitle-1': smallBreakpoint }"
            class="text-h4 justify-center font-weight-bold mt-n3"
            v-html="slide.messageCenterMessageLabel"
          />
          <v-img
            :src="findMessageIdInSlidesAndMessagesList(slide)"
            height="auto"
            min-height="450"
            width="100%"
            class="image-gradient"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
          </v-img>
          <div class="centered-text-image">
            <v-card-title
              :class="{ 'text-subtitle-1': smallBreakpoint }"
              class="text-h5 justify-center font-weight-bold"
              v-html="slide.messagingCenterMessageValue"
            />
          </div>
        </div>

        <div v-if="slide.messageCenterMessageTypeId === 2">
          <v-card-title
            :class="{ 'text-subtitle-1': smallBreakpoint }"
            class="text-h4 justify-center font-weight-bold"
            v-html="slide.messageCenterMessageLabel"
          />
          <div
            :style="smallBreakpoint ? 'min-height: 350px' : 'min-height: 400px'"
          >
            <v-divider />

            <div class="centered-text">
              <v-card-text
                class="px-15 mt-5 text-justify justify-center text-body-1 message-overflow"
                v-html="slide.messagingCenterMessageValue"
              />
            </div>
          </div>
        </div>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="markMessageAsRead(slide)"
            :loading="isLoading"
          >
            Mark as Read
            <v-icon right dark> mdi-email-open </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';

import API from '@/Core.Service.Domain/MessageCenter/Users/API';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PopUpMessageTypeImage',

  props: {
    slides: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getMessagesList: 'MessageCenterManagementStore/getMessagesList',
      getMessagesByUser: 'MessageCenterUsersStore/getMessagesByUser',
    }),

    messagesList() {
      return this.$can('administer', 'control')
        ? this.getMessagesList
        : this.getMessagesByUser;
    },
  },

  async created() {
    if (this.$can('administer', 'control')) {
      await this.getAllMessagesFromMessageCenter();
    } else {
      await this.setMessagesByUser();
    }
  },

  data() {
    return {
      cycle: false,
      isLoading: false,
    };
  },

  methods: {
    ...mapActions({
      getAllMessagesFromMessageCenter:
        'MessageCenterManagementStore/getAllMessagesFromMessageCenter',
      setUnreadMessagesCount: 'MessageCenterUsersStore/setUnreadMessagesCount',
      setMessagesByUser: 'MessageCenterUsersStore/setMessagesByUser',
    }),
    dateTimeToUSFormatFactory,
    toggleMessageRead(item) {
      console.log(item);
    },

    findMessageIdInSlidesAndMessagesList(slide) {
      const message = this.messagesList.find(
        (message) =>
          message.messageCenterMessageLabel === slide.messageCenterMessageLabel
      );

      const img = new Image();

      if (!message) {
        return (img.src = '');
      }

      //TODO do the implementation for the image source when the upload feature is ready
      // img.src = `./images/${
      //   this.$can('administer', 'control')
      //     ? message.messageCenterMessageId
      //     : message.userMessageCenterMessageId
      // }.jpg`;
      // TODO remove this line when the upload feature is ready
      img.src = `./images/38.jpg`;

      return img.src;
    },

    async markMessageAsRead(message) {
      try {
        this.isLoading = true;
        const messageId = message.userMessageCenterMessageId;
        await API.markMessageAsRead(messageId);
        await this.setUnreadMessagesCount();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.container
  position: relative
  text-align: center
  min-height: 500px

.v-image
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.5))) !important

.centered-text-image
  position: absolute
  bottom: 20px
  width: 100%
  left: 50%
  transform: translate(-50%, 0)
  color: white
  z-index: 2

.centered-text
  position: absolute
  width: 100%
  left: 50%
  transform: translate(-50%, 0)

.message-overflow
  height: 350px
  line-height: 1.5
  overflow-y: scroll
  -ms-overflow-style: none
  scrollbar-width: none

.message-overflow::-webkit-scrollbar
  display: none

.image-gradient
  position: relative
  overflow: hidden

.image-gradient::before
  content: ''
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: linear-gradient(to bottom,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.1) 40%, rgba(255, 255, 255, 0.9) 80%)
  z-index: 1
</style>
