<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="778.03866,98.93303,608.36842,357.3158"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="isActive ? '0.3' : '0.2'"
      :fill="color"
      fill-rule="nonzero"
      :stroke="color"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
      pointer-events="all"
    >
      <path
        d="M892.17024,289.43303v-6.8421h-20.47369v-14.78948h-93.15789v-70.47368h93.15789v-24.78948h-17.89474v-73.10526h366.78947v71.52632h61.63159v118.47368h-106.31579c0,15.64297 0,61.7346 0,77.36843c36.31579,11.57895 49.47368,56.84211 49.47368,56.84211l142.63158,11.57895l-1.05263,-6.31579l17.36842,-0.52632l1.57895,27.36842l-261.57895,-0.52632v-84.21053l25.78947,-1.57895l6.87117,-80z"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'B20Drawing',
  props: {
    color: {
      type: String,
      default: '#2E3C54',
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleMouseEnter(event) {
      if (this.isActive) {
        this.$emit('mouseenter', event);
      }
    },
    handleMouseMove(event) {
      if (this.isActive) {
        this.$emit('mousemove', event);
      }
    },
    handleMouseLeave(event) {
      if (this.isActive) {
        this.$emit('mouseleave', event);
      }
    },
  },
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
