<template>
  <v-card
    flat
    :height="toolBarHeight"
    active-class="roi-predictions-bar d-flex flex-row"
    class="roi-predictions-bar"
    color="rgba(0, 0, 0, 0)"
  >
    <div class="d-flex flex-row align-center">
      <div v-if="isRouteVisualAidSummary" class="d-flex flex-row align-center">
        <v-btn
          class="rounded-pill mr-3"
          color="primary"
          depressed
          large
          @click="gotToROIPredictionsList"
        >
          <div class="subtitle-2 white--text text-truncate font-weight-bold">
            Back to ROI's List
          </div>
        </v-btn>
        <span class="primary--text text-h5 font-weight-bold ml-16">
          {{ defaultSite.name }}
        </span>
      </div>
      <v-toolbar dense :width="toolBarWidth" v-if="isRouteVisualAidList">
        <v-autocomplete
          dense
          :items="ROIPredictionsSites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          class="mt-6 mr-3"
          return-object
          @change="
            (selectedSite) =>
              setDefaultSiteFromROIPredictionsDomain(selectedSite)
          "
        >
          <template v-slot:label>
            <span class="primary--text text-caption font-weight-bold">
              Site
            </span>
          </template>
        </v-autocomplete>
      </v-toolbar>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ROIPredictionsBar',

  props: {
    ROIPredictionsSites: {
      type: Array,
      default: () => [],
    },
  },

  async created() {
    await this.setDefaultSiteFromROIPredictionsDomain(this.defaultSite)
    this.siteId = this.defaultSite.siteId
    this.gotToROIPredictionsList()
  },

  data() {
    return {
      toolBarHeight: 50,
      toolBarWidth: 1175,
      selectedSite: null,
      siteId: null,
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'SitesStore/ROIPredictionsStore/roiPredictionsBySiteIsLoading',
      defaultSite: 'session/defaultSite',
    }),

    isRouteVisualAidSummary() {
      return this.$route.meta.visualAid === 'summary'
    },

    isRouteVisualAidList() {
      return this.$route.meta.visualAid === 'list'
    },
  },

  watch: {
    siteId: {
      handler(current, initial) {
        if (initial && current !== initial) this.gotToROIPredictionsList()
      },
    },
  },

  methods: {
    ...mapActions({
      getROIPredictionsBySite:
        'SitesStore/ROIPredictionsStore/getROIPredictionsBySite',
      setDefaultSite: 'session/setDefaultSite',
    }),

    async setDefaultSiteFromROIPredictionsDomain(selectedSite) {
      this.selectedSite = selectedSite || this.ROIPredictionsSites[0]
      const siteId = this.selectedSite.siteId || this.selectedSite.id

      try {
        this.$emit('isLoading', true)
        await this.getROIPredictionsBySite({
          siteId,
        })
        this.setDefaultSite(this.selectedSite)
        this.siteId = siteId
      } catch (error) {
        console.error(error)
      } finally {
        this.$emit('isLoading', false)
      }
    },

    gotToROIPredictionsList() {
      this.$router.push({
        name: 'ROIPredictionsList',
        params: {
          siteId: this.selectedSite.siteId,
        },
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.roi-predictions-bar
  position: fixed
  top: 100px
  z-index: 1
</style>
