<template>
  <v-container fluid>
    <v-row
      v-for="row in Math.ceil(utilityCompaniesList.length / cardsPerRow)"
      :key="row"
    >
      <v-col
        v-for="uCompany in utilityCompaniesList.slice(
          (row - 1) * cardsPerRow,
          row * cardsPerRow
        )"
        :key="uCompany.id"
      >
        <UtilityCompanyCard :UCompany="uCompany" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { searchByTermFactory } from '@/Core.Patterns/Factory';
import { sortAlphabetically } from '@/Core.Patterns/Strategy';

import UtilityCompanyCard from '@/Core.Service.Domain/Sites/UtilityCompanies/Components/UtilityCompanyCard.vue';

export default {
  name: 'UtilityCompaniesList',

  components: {
    UtilityCompanyCard,
  },

  computed: {
    ...mapGetters({
      searchTerm: 'SitesStore/UtilityCompaniesStore/utilityCompanySearchTerm',
      UtilityCompaniesList: 'SitesStore/UtilityCompaniesStore/utilityCompanies',
    }),

    utilityCompaniesList() {
      return this.filteredItems.length
        ? sortAlphabetically(this.filteredItems)
        : sortAlphabetically(this.UtilityCompaniesList);
    },
  },

  watch: {
    searchTerm() {
      this.filteredItems = searchByTermFactory(
        this.searchTerm,
        ['name'],
        this.UtilityCompaniesList
      );
    },
  },

  data() {
    return {
      filteredItems: [],
      cardsPerRow: 3,
    };
  },
};
</script>

<style lang="sass" scoped>
.col
  flex-grow: 0
</style>
