<template>
  <div>
    <slot name="admin-menu"></slot>
    <v-card class="mx-auto" max-width="500" color="primary" elevation="0">
      <v-list class="mt-5" :class="mini ? '' : 'ml-2'">
        <v-list-group v-for="item in allowedItems" :key="item.title" no-action>
          <template v-slot:appendIcon>
            <v-list-item-icon class="ma-auto" v-if="hasChildren(item)">
              <v-icon small color="aliceBlue"> mdi-chevron-down </v-icon>
            </v-list-item-icon>
            <div else></div>
          </template>

          <template v-slot:prependIcon v-if="item.action">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="ma-auto mt-8">
                  <v-icon v-bind="attrs" v-on="on" color="aliceBlue">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </template>

          <template v-slot:prependIcon v-else>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="ma-auto">
                  <v-icon v-bind="attrs" v-on="on" color="aliceBlue">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </template>

          <template v-slot:activator>
            <v-list-item-content
              class="ml-n6 aliceBlue--text mt-8"
              v-if="item.action"
              @click="() => item.action()"
            >
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
            <router-link :to="item.to" v-else>
              <v-list-item-content class="ml-n6 aliceBlue--text">
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </router-link>
          </template>

          <template>
            <v-list-item
              v-for="child in allowedChildren(item.items)"
              :key="child.title"
              :to="child.to"
            >
              <v-list-item-icon class="ml-n7">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="aliceBlue" v-bind="attrs" v-on="on">
                      {{ child.icon }}
                    </v-icon>
                  </template>
                  <span>{{ child.title }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content class="ml-n6 aliceBlue--text">
                <v-list-item-title>
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'MenuLinks',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    mini: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    allowedItems() {
      return this.items.filter((item) => item.active());
    },
  },

  methods: {
    hasChildren(item) {
      return (
        item.items &&
        item.items.length &&
        item.items.some((child) => (child.active ? child.active() : true))
      );
    },

    allowedChildren(item) {
      if (item) {
        return item.filter((child) => (child.active ? child.active() : true));
      }
    },
  },
};
</script>
