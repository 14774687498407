<template>
  <v-container fluid>
    <v-row class="ml-1">
      <PortfolioBar />
    </v-row>
    <v-row class="mt-12">
      <v-col v-if="isLoading">
        <ProgressLinearLoaderVue :isLoading="isLoading" />
      </v-col>
      <v-col>
        <PortfolioList :portfolioList="portfolioList" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '@/Core.Service.Domain/ViewSupport/Portfolio/API';
import PortfolioList from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/PortfolioList';
import ProgressLinearLoaderVue from '@/Core.UI.Domain/Components/ProgressLinearLoader.vue';

import PortfolioBar from '@/Core.Service.Domain/ViewSupport/Portfolio/Components/PortfolioBar.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PortfolioView',

  components: { PortfolioList, ProgressLinearLoaderVue, PortfolioBar },

  computed: {
    ...mapGetters({
      defaultSite: 'session/defaultSite',
    }),
  },

  data() {
    return {
      portfolioList: [],
      isLoading: true,
    };
  },

  async created() {
    try {
      this.portfolioList = await API.getPortfolioSites();
      if (!this.defaultSite) {
        this.setDefaultSite(this.portfolioList[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      setDefaultSite: 'session/setDefaultSite',
    }),
  },
};
</script>
