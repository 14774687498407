<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        tile
        v-bind="attrs"
        v-on="on"
        @click="openMessagesCenter"
        color="primary"
        class="mr-2"
      >
        <v-badge
          v-if="messagesCount > 0"
          :content="messagesCount"
          :value="messagesCount"
          overlap
          left
          offset-x="-5"
          offset-y="-4"
          color="accentOrange"
        >
          <v-icon left :class="{ bell: messagesCount > 0 }"> mdi-bell </v-icon>
        </v-badge>
        Messages
      </v-btn>
    </template>
    <span>
      You have {{ messagesCount }} unread message{{ getCountMessageInPlural }}
    </span>
  </v-tooltip>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MessageCenterUserButton',

  computed: {
    ...mapGetters({
      messagesCount: 'MessageCenterUsersStore/getUnreadMessagesCount',
    }),

    getCountMessageInPlural() {
      return this.messagesCount > 1 ? 's' : '';
    },
  },

  methods: {
    openMessagesCenter() {
      this.$emit('openMessagesCenter');
      this.$router.push({ name: 'MessageCenterMyMessages' });
    },
  },
};
</script>
<style lang="sass" scoped>
@mixin ring-animation($transform-prefix)
  0%
    #{$transform-prefix}: rotate(0)
  1%
    #{$transform-prefix}: rotate(30deg)
  3%
    #{$transform-prefix}: rotate(-28deg)
  5%
    #{$transform-prefix}: rotate(34deg)
  7%
    #{$transform-prefix}: rotate(-32deg)
  9%
    #{$transform-prefix}: rotate(30deg)
  11%
    #{$transform-prefix}: rotate(-28deg)
  13%
    #{$transform-prefix}: rotate(26deg)
  15%
    #{$transform-prefix}: rotate(-24deg)
  17%
    #{$transform-prefix}: rotate(22deg)
  19%
    #{$transform-prefix}: rotate(-20deg)
  21%
    #{$transform-prefix}: rotate(18deg)
  23%
    #{$transform-prefix}: rotate(-16deg)
  25%
    #{$transform-prefix}: rotate(14deg)
  27%
    #{$transform-prefix}: rotate(-12deg)
  29%
    #{$transform-prefix}: rotate(10deg)
  31%
    #{$transform-prefix}: rotate(-8deg)
  33%
    #{$transform-prefix}: rotate(6deg)
  35%
    #{$transform-prefix}: rotate(-4deg)
  37%
    #{$transform-prefix}: rotate(2deg)
  39%
    #{$transform-prefix}: rotate(-1deg)
  41%
    #{$transform-prefix}: rotate(1deg)
  43%
    #{$transform-prefix}: rotate(0)
  100%
    #{$transform-prefix}: rotate(0)

.bell
  display: block
  width: 40px
  height: 40px
  color: #9e9e9e
  margin-top: -7px
  -webkit-animation: ring 4s .7s ease-in-out infinite
  -webkit-transform-origin: 50% 4px
  -moz-animation: ring 4s .7s ease-in-out infinite
  -moz-transform-origin: 50% 4px
  animation: ring 4s .7s ease-in-out infinite
  transform-origin: 50% 4px

@-webkit-keyframes ring
  @include ring-animation(-webkit-transform)

@-moz-keyframes ring
  @include ring-animation(-moz-transform)

@keyframes ring
  @include ring-animation(transform)

.v-badge::v-deep
  .v-badge__badge
    width: auto
    letter-spacing: -1px
    padding: 2px 5px
    padding-left: 4px

.v-icon
  font-size: 1.2rem !important
</style>
