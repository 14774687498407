<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="1805.07102,1007.86924,207.47058,358.47059"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="isActive ? '0.3' : '0.2'"
      :fill="color"
      fill-rule="nonzero"
      :stroke="color"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
      pointer-events="all"
    >
      <path
        d="M1843.80631,1365.83982v-168.23529h-38.23529v-20.41176h38.23529v-168.82353h141v144.11765h27.23529v18.05882h-8.41176v38.41177h-18.82353v156.88235z"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'b3Drawing',
  props: {
    color: {
      type: String,
      default: '#2E3C54',
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleMouseEnter(event) {
      if (this.isActive) {
        this.$emit('mouseenter', event);
      }
    },
    handleMouseMove(event) {
      if (this.isActive) {
        this.$emit('mousemove', event);
      }
    },
    handleMouseLeave(event) {
      if (this.isActive) {
        this.$emit('mouseleave', event);
      }
    },
  },
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
