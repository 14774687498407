const addressDetail = (place) => {
  const parseComponent = (types, nameForm) => {
    let value = null;
    if (typeof types == 'string') {
      types = [types];
    }
    for (let i = 0; i < types.length; i++) {
      const component = place.address_components.find(
        (object) => object.types.indexOf(types[i]) !== -1,
      );
      if (component != null) {
        value = component[nameForm];
        break;
      }
    }
    return value;
  };

  // console.log(`!! ADDRESS`, address);
  return {
    // streetNumber: parseComponent("street_number", "long_name"),
    // street: parseComponent("route", "long_name"),
    city: parseComponent(['locality', 'sublocality', 'sublocality_level_1', 'administrative_area_level_2'], 'long_name'),
    state: parseComponent('administrative_area_level_1', 'long_name'),
    postalCode: parseComponent('postal_code', 'long_name'),
    country: parseComponent('country', 'long_name'),
    address: place.formatted_address,
    lat: Number.parseFloat(place.geometry.location.lat()).toFixed(5),
    lng: Number.parseFloat(place.geometry.location.lng()).toFixed(5),
  };
};

export function mapPlaceComponents(pl) {
  // console.log(`PLACE`, JSON.stringify(pl));
  // console.log(`PLACE`, pl);
  return addressDetail(pl);
}