import embed from '../embedReports/IAQEmbed.js';
import slicer from '../slicerReports/IAQSlicer.js';

import { getFunctionAndFileName } from '@/Core.Patterns/Factory';

//Dashboard
//Site Slicer - vwSite - SiteName
//Monitor Name Slicer - vwSensorNames - MonitorFriendlyName
//Overtime
//Date Slicer - vwReportingDateTime - Date

const embedAndSlice = async (container, embedConfiguration) => {
    const pbiEmbed = await embed.getEmbed(container, embedConfiguration);
    try {
      const visuals = await getVisuals(pbiEmbed);
      // Retrieve the target visuals.
      await slicer.setSlicers(embedConfiguration.reportParameters, visuals);
    } catch (error) {
      const { functionName, fileName } = getFunctionAndFileName(error);
      console.error(`Error in ${functionName} in ${fileName} =>`, { error });
    }
  },
  getVisuals = async (pbiEmbed) => {
    const pages = await pbiEmbed.getPages();
    // Retrieve the active page.
    const page = pages.filter((page) => {
      return page.isActive;
    })[0];

    return await page.getVisuals();
  };

export default {
  embedAndSlice,
};
