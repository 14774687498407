import actions from '@/Core.Service.Domain/Strategies/Strategies/Store/actions.js';
import getters from '@/Core.Service.Domain/Strategies/Strategies/Store/getters.js';
import mutations from '@/Core.Service.Domain/Strategies/Strategies/Store/mutations.js';

const state = {
  strategyCurrentRoute: '',
  sitesWithStrategies: [],
  selectedSiteStrategies: [],
  selectedSiteStrategiesIsLoading: true,
  strategySearchTerm: '',
  strategyTodaysHistory: [],
  isHistoryLoading: false,
  strategyInfo: {
    strategyName: '',
    strategyId: '',
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
