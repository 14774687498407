<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="1803.59743,712.58022,77.83644,45.2381"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    <g
      :fill-opacity="isActive ? '0.3' : '0.2'"
      :fill="color"
      fill-rule="nonzero"
      :stroke="color"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
      pointer-events="all"
    >
      <path
        d="M1804.09743,757.31832v-44.2381h76.83644v44.2381z"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'b4BDrawing',
  props: {
    color: {
      type: String,
      default: '#2E3C54',
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleMouseEnter(event) {
      if (this.isActive) {
        this.$emit('mouseenter', event);
      }
    },
    handleMouseMove(event) {
      if (this.isActive) {
        this.$emit('mousemove', event);
      }
    },
    handleMouseLeave(event) {
      if (this.isActive) {
        this.$emit('mouseleave', event);
      }
    },
  },
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
