<template>
  <v-container fluid>
    <v-row class="ml-1">
      <ScheduledReportsBar />
    </v-row>
    <v-row class="mt-12">
      <v-col>
        <ScheduledReportsList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ScheduledReportsList from '@/Core.Service.Domain/ViewSupport/Reports/Components/ScheduledReportsList.vue';

import ScheduledReportsBar from '@/Core.Service.Domain/ViewSupport/Reports/Components/ScheduledReportsBar.vue';

export default {
  name: 'ScheduledReportsIndex',

  components: {
    ScheduledReportsList,
    ScheduledReportsBar,
  },

  data() {
    return {
      scheduledReportsList: [],
      cardWidth: 370,
      cardHeight: 576,
      isLoading: true,
    };
  },

  created() {
    try {
      this.setScheduledReportsList();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      setScheduledReportsList:
        'ViewSupportStore/ReportsStore/setScheduledReportsList',
    }),
  },
};
</script>
