// These routes are old fashioned and will be replaced by the new route system.
// They should be nested hence a router-view in the parent component.

const UserIndexRoute = {
  path: '/administration/users',
  name: 'UserIndex',
  component: () => import('@/modules/administration/users/_components/Index'),
  meta: { permission: 'users:read' },
};

const UserEditRoute = {
  path: '/administration/users/edit',
  name: 'UserEdit',
  component: () => import('@/modules/administration/users/_components/Edit'),
};

const UserCreateRoute = {
  path: '/administration/users/create',
  name: 'UserCreate',
  component: () => import('@/modules/administration/users/_components/Create'),
  meta: { permission: 'users:create' },
};

const AdminReportIndexRoute = {
  path: '/administration/reports',
  name: 'AdminReportIndexRoute',
  component: () => import('@/modules/administration/reports/_components/Index'),
};

const AdminReportCreateRoute = {
  path: '/administration/reports/create',
  name: 'AdminReportCreateRoute',
  component: () =>
    import('@/modules/administration/reports/_components/Create'),
};

const AdminReportEditRoute = {
  path: '/administration/reports/:id',
  name: 'AdminReportEditRoute',
  component: () => import('@/modules/administration/reports/_components/Edit'),
};

const ADFSyncRoute = {
  path: '/administration/adf-sync',
  name: 'ADFSyncIndex',
  component: () =>
    import('@/modules/administration/adf_sync/_components/Index'),
  meta: { permission: 'users:create' },
};

const UsersRolesCacheRoute = {
  path: '/administration/users/roles-cache',
  name: 'UsersRolesCache',
  component: () => import('@/Core.Service.Domain/Users/Views'),
  meta: { permission: 'users:read' },
};

const UserUnsubscribeRoute = {
  path: '/user-unsubscribe',
  name: 'UserUnsubscribe',
  component: () =>
    import('@/Core.Service.Domain/Users/Views/UserUnsubscribe.vue'),
  meta: { allowAnonymous: true },
};

export default [
  UserIndexRoute,
  UserEditRoute,
  UserCreateRoute,
  AdminReportIndexRoute,
  AdminReportCreateRoute,
  AdminReportEditRoute,
  ADFSyncRoute,
  UsersRolesCacheRoute,
  UserUnsubscribeRoute,
];
