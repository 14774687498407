const sitesWithStrategies = (state) => state.sitesWithStrategies;
const selectedSiteStrategies = (state) => state.selectedSiteStrategies;
const strategyCurrentRoute = (state) => state.strategyCurrentRoute;
const selectedSiteStrategiesIsLoading = (state) =>
  state.selectedSiteStrategiesIsLoading;

const getStrategySearchTerm = (state) => state.strategySearchTerm;

const getStrategyTodaysHistory = (state) => state.strategyTodaysHistory;

const getIsHistoryLoading = (state) => state.isHistoryLoading;

const getStrategyInfo = (state) => state.strategyInfo;

export default {
  sitesWithStrategies,
  selectedSiteStrategies,
  strategyCurrentRoute,
  selectedSiteStrategiesIsLoading,
  getStrategySearchTerm,
  getStrategyTodaysHistory,
  getIsHistoryLoading,
  getStrategyInfo,
};
