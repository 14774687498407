import * as powerbi from 'powerbi-client';

import slicer from '../slicerReports/utilityForecastSlicer';

import { getFunctionAndFileName } from '@/Core.Patterns/Factory';

let report = null;
let visuals = null;

// Embed a Power BI report in the given HTML element with the given configurations
// Read more about how to embed a Power BI report in your application here: https://go.microsoft.com/fwlink/?linkid=2153590
const getEmbed = async (container, configuration) => {
  /*-----------------------------------------------------------------------------------+
    |    Don't change these values here: access token, embed URL and report ID.          | 
    |    To make changes to these values:                                                | 
    |    1. Save any other code changes to a text editor, as these will be lost.         |
    |    2. Select 'Start over' from the ribbon.                                         |
    |    3. Select a report or use an embed token.                                       |
    +-----------------------------------------------------------------------------------*/
  // Embed the report and display it within the div container.
  const pbi = new powerbi.service.Service(
    powerbi.factories.hpmFactory,
    powerbi.factories.wpmpFactory,
    powerbi.factories.routerFactory
  );

  report = pbi.embed(container, configuration);

  // report.on will add an event handler
  report.on('loaded', async () => {
    try {
      const pages = await report.getPages();
      // Retrieve the active page.
      const [pageWithSlicer] = pages.filter((page) => {
        return page.isActive;
      });

      visuals = await pageWithSlicer.getVisuals();

      await slicer.setSlicers(configuration.reportParameters, visuals, false);
      report.off('loaded');
    } catch (errors) {
      const { functionName, fileName } = getFunctionAndFileName(errors);
      console.error(`Error in ${functionName} in ${fileName} =>`, errors);
    }
  });

  //report.off removes all event handlers for a specific event
  // clears the event handlers before starting the event listener
  report.off('dataSelected');

  // report.on start the event listener
  report.on('dataSelected', async (event) => {
    // store the events
    const { detail } = event;

    if (detail.visual.title === 'Utility Slicer') {
      const pages = await report.getPages();
      // Retrieve the active page.
      const pageWithSlicer = pages.filter((page) => {
        return page.isActive;
      })[0];

      const visuals = await pageWithSlicer.getVisuals();
      const newSite = detail.dataPoints[0].identity[0].equals;
      report.config.reportParameters.SiteName = newSite;

      await slicer.setEndUseSlicerVisual(
        report.config.reportParameters,
        visuals
      );
    }
  });

  return report;
};

export default {
  getEmbed,
};
