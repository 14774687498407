<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="font-weight-bold ml-n3">
          My Messages
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="mt-5">
        <MessageCenterMessagesList />
      </v-card-text>

      <div style="flex: 50 auto"></div>
    </v-card>
  </v-dialog>
</template>
<script>
import MessageCenterMessagesList from '@/Core.Service.Domain/MessageCenter/Users/Components/MessageCenterMessagesList.vue';
export default {
  name: 'MessageCenterDialog',

  components: {
    MessageCenterMessagesList,
  },

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
      this.$router.go(-1);
    },
  },
};
</script>
