<template>
  <v-card class="ma-2" flat outlined min-height="300">
    <v-toolbar>
      <v-toolbar-title>
        <span
          class="primary--text"
          v-html="selectedMessage.messageCenterMessageLabel"
        />
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-card-actions>
        <ActionButtonsVue
          :buttonsList="actionButtons"
          :item="selectedMessage"
          :outlined="selectedMessage.isRead"
        />
      </v-card-actions>
    </v-toolbar>

    <v-card-text>
      <div class="mb-3">
        {{
          dateTimeToUSFormatFactory(
            selectedMessage.messageCenterMessageCreateDateTimeOffset,
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }
          )
        }}
      </div>
      <div
        class="primary--text"
        v-html="selectedMessage.messagingCenterMessageValue"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import { dateTimeToUSFormatFactory } from '@/Core.Patterns/Factory';
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';

import API from '@/Core.Service.Domain/MessageCenter/Users/API';

export default {
  name: 'MessageCenterMessagePanel',

  props: {
    selectedMessage: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ActionButtonsVue,
  },

  data: (instance) => ({
    actionButtons: [
      {
        icon: (item) => (item.isRead ? 'email-outline' : 'email-open-outline'),
        toolTip: (item) => (item.isRead ? 'Mark as Unread' : 'Mark as Read'),
        action: async (item) => {
          instance.itemIsLoading = item.id;
          await instance.toggleMessageRead(item);
          instance.itemIsLoading = null;
        },
        loading: (item) =>
          item.userMessageCenterMessageId === instance.itemIsLoading,
      },
    ],
    itemIsLoading: null,
  }),

  methods: {
    dateTimeToUSFormatFactory,

    async toggleMessageRead(message) {
      const messageId = message.userMessageCenterMessageId;
      try {
        if (message.isRead) {
          await API.markMessageAsUnread(messageId);
        } else {
          await API.markMessageAsRead(messageId);
        }
        message.isRead = !message.isRead;
        this.$emit('toggle');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
