<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="950.5 459.5 188.33332 446"
    style="width: 100%; height: 100%; pointer-events: none"
  >
    >
    <g
      fill-opacity="0.3"
      :fill="color"
      :stroke="color"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      pointer-events="all"
    >
      <!-- Path with hover and click events -->
      <path
        d="M1049.12698,905v-190h-71.31984v-4.48854h-13.00197v4.48854h-13.80517v-97h98.12698v-158h35.96099v5.21735h12.06909v-5.21735h41.17626v445z"
        @mouseenter="handleMouseEnter"
        @mouseleave="$emit('mouseleave')"
        @mousemove="handleMouseMove"
        @click="$emit('click')"
        id="interactive-path"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'b7Drawing',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleMouseEnter(event) {
      this.$emit('mouseenter', event);
    },
    handleMouseMove(event) {
      this.$emit('mousemove', event);
    },
  },
};
</script>

<style scoped>
#interactive-path {
  transition: fill-opacity 0.2s;
}
#interactive-path:hover {
  fill-opacity: 0.5;
}
</style>
