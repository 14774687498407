<template>
  <ValidationProvider
    :name="$attrs.label"
    :rules="rules"
    :multiple="$attrs.multiple"
    :hint="$attrs.hint"
    :persistent-hint="$attrs.persistent - hint"
  >
    <template v-slot="{ errors, valid }">
      <v-select
        v-model="innerValue"
        :error-messages="errors"
        :success="valid"
        v-bind="$attrs"
        v-on="$listeners"
        @change="handleSelectChange"
        outlined="outlined"
        ref="validateSelectField"
      />
    </template>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    innerValue: '',
    hint: '',
  }),
  watch: {
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    this.$validator.attach;
    if (this.value) {
      this.innerValue = this.value;
    }
  },

  methods: {
    handleSelectChange() {
      this.$emit('change-value', this.innerValue);
    },
  },
};
</script>
