import actions from '@/Core.Service.Domain/Controls/DaikinThermostats/Store/actions.js';
import getters from '@/Core.Service.Domain/Controls/DaikinThermostats/Store/getters.js';
import mutations from '@/Core.Service.Domain/Controls/DaikinThermostats/Store/mutations.js';

const state = {
  daikinThermostatsList: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
